@import '../../../../../config/variables.scss';
@import '../../../../../config/mixins.scss';

.product-item {
  flex: 1;
  @include gt-sm {
    display: flex;
  }
}

.product-item-details {
  flex: 1;
}

.product-item-children {
  margin: ($main-spacing/-2);
  margin-top: 0;
  padding: $main-spacing/2;
  flex: 1;
  background-color: darken($color-background, 10%);
  @include gt-sm {
    border-left: 1px solid $color-primary;
    margin-left: $main-spacing;
    margin: ($main-spacing/-2);
  }
}

.product-item-parents {
  margin: ($main-spacing/-2);
  margin-top: 0;
  padding: $main-spacing/2;
  flex: 1;
  //   background-color: darken($color-background, 10%);
  @include gt-sm {
    border-left: 1px solid $color-primary;
    margin-left: $main-spacing;
    margin: ($main-spacing/-2);
  }
}

.product-item-header {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: $main-spacing/2;
}

.product-subitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: $main-spacing/4;
  padding-bottom: $main-spacing/4;
  border-bottom: 1px solid darken($color-background, 8%);
}

.product-subitem-details {
  display: flex;
  font-size: 1rem;
}

.product-subitem-name-code {
  .product-subitem-name {
    font-weight: 600;
    display: block;
    margin-bottom: 0;
  }
  .product-subitem-code {
    display: block;
    font-size: 0.75rem;
  }
}
