@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.offer-accept{
align-items: center;
padding-bottom: $main-spacing *1.5;
.accept-info {
  color: $color-secondary;
  font-style: italic;
  padding: $main-spacing 0 $main-spacing *1.5;
}
}