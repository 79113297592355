$main-font: 'Source Sans Pro', sans-serif;
$border-radius: 5px;
$transition-time: 0.3s;

//COLORS
$color-background: #fff;
$color-primary: #0072ea;
$color-primary-light: #ecf3fc;
$color-text: #333;
$color-text-light: #fff;
$color-secondary: #8cba51;
$color-text-placeholder: #e6e6e6;

$color-danger: #ea282f;
$color-success: #00a45a;
$color-warning: #ec8628;
$color-notice: #7d7d78;
$color-disabled: #aaa;

//SPACING
$main-spacing: 20px;

//SHADOWS
$soft-shadow: 0 0 $main-spacing * 2.5 (-$main-spacing/2) rgba(0, 0, 0, 0.15);
$soft-shadow-bottom: 0 $main-spacing $main-spacing (-$main-spacing/2)
  rgba(0, 0, 0, 0.15);

//DIMENSIONS
$logo-base-size: 30px;
$icon-width: 50px;
$toolbar-height: 50px;
$icon-height-small: 25px;
$icon-height-big: 40px;
$button-height: 35px;
$language-icon-wdith: 22px;
$checkbox-side: 20px;

$sidebar-width: 320px;

$big-logo-height: 165px;
$welcome-header-height: 110px;
$form-footer-height: 110px;

$form-width: 500px;
$max-page-width: 1300px;
$header-height-mobile: 50px;
$header-height-desktop: 70px;
$trial-height-mobile: 70px;
$trial-height-desktop: 70px;

$height-icon: $main-spacing * 0.9;
$width-line: 30px;
$height-line: 3px;

$super-big: 400px;

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 920px,
  lg: 1200px,
  xl: 1368px,
);

//ANIMATIONS
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;
$transition-time: 0.3s;
