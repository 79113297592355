@import '../../config/variables.scss';
@import '../../config/mixins.scss';
.offer-container {
  background: darken($color-background, 5%);
  height: 100%;
}

.offer-wrapper {
  background: $color-background;
  padding: $main-spacing * 0.5;
  @include gt-sm {
    padding: $main-spacing * 1.5;
  }
}
