@import '../../../config/variables.scss';

.tabs-container {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 2;
  margin-bottom: $main-spacing;
  overflow: hidden;
  box-shadow: $soft-shadow;

  &.auto {
    width: auto;
  }
}
.tabs-option {
  flex: 1;
  text-align: center;
  padding: $main-spacing * 2 $main-spacing;
  background: $color-background;
  border: none;
  font-family: $main-font;
  font-size: 0.8rem;
  font-weight: 600;
  outline: none;
  cursor: pointer;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: $color-text;
  }
  &.active {
    border-bottom: 2px solid $color-secondary;
  }
}
