@import '../../../config/variables.scss';

.status-shared {
  margin-bottom: $main-spacing;
}

.status-shared-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
