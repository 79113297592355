@import "../../../config/variables.scss";

.icon-wrapper {
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    svg {
      width: $main-spacing * 1.5;
      height: $main-spacing * 1.5;
      fill: $color-secondary;
      transition: fill $transition-time;
    }
  }
  &.big {
    .icon {
      svg {
        width: $main-spacing * 2;
        height: $main-spacing * 2;
      }
    }
  }
  &.small {
    .icon {
      svg {
        width: $main-spacing;
        height: $main-spacing;
      }
    }
  }
}
