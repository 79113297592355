@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.offer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .offer-title {
    font-size: 1.6em;
    text-transform: uppercase;
    margin-right: $main-spacing * 2;

    font-weight: 700;
    padding: $main-spacing/2 0;

    @include gt-sm {
      white-space: nowrap;
    }
  }

  .offer-number {
    font-weight: 400;
  }

  .offer-buttons {
    flex: 1;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
}

.offer-subheader {
  padding-top: $main-spacing;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: $main-spacing * 2;

  .offer-valid {
    display: flex;
    font-size: 1.2em;
    flex-direction: column;

    .offer-date {
      font-weight: 600;
    }
  }

  .offer-days {
    font-style: italic;
    display: flex;
    justify-content: flex-end;
    font-size: 1.3em;
    padding-top: $main-spacing/2;
    .remaning-days {
      font-weight: 800;
      padding-right: $main-spacing / 2.5;
    }
  }

  @include gt-sm {
    flex-direction: row;
    align-items: center;

    .offer-valid {
      flex-direction: row;

      .offer-date {
        padding-left: $main-spacing / 4;
      }
    }

    .offer-days {
      padding-top: 0;
    }
  }
}

.offer-header-top {
  margin-bottom: $main-spacing;
}
