@import '../../../config/variables';
@import '../../../config/mixins';

.pagination {
  margin: $main-spacing 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  font-weight: 300;

  li {
    margin: $main-spacing / 2 $main-spacing / 4;
  }
  flex-wrap: wrap;

  @media screen and (min-width: map-get($breakpoints,sm)) {
    li {
      margin: 0 $main-spacing / 2;
    }
    .previous,
    .next {
      width: auto;
    }
  }

  .button {
    padding: 0$main-spacing;
    outline: none;
    min-width: unset;
    min-height: unset;
    height: 40px;
  }

  .button-link {
    background-color: rgba($color-secondary, 0.25);
    color: $color-text-light;
  }

  .button-link-active {
    border: 1px solid $color-secondary;
    color: $color-text-light;
    background: $color-secondary;
    font-weight: 700;
  }

  .disabled {
    .button {
      background-color: rgba($color-background, 0.5);
      color: rgba($color-text, 0.25);
      cursor: not-allowed;
      box-shadow: none;
    }
  }
}
