@import "../../config/variables.scss";
@import "../../config/mixins.scss";

.searchbar-wrapper {
  position: relative;
  min-width: 100%;
  background-color: $color-text;
  border: 1px solid $color-text;
  border-radius: $main-spacing * 2;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  margin-top: $main-spacing * 2;
  display: flex;

  .button-search {
    margin: $main-spacing / 5;
    padding-left: $main-spacing;
    padding-right: $main-spacing;

    @include gt-xs {
      padding-left: $main-spacing * 2;
      padding-right: $main-spacing * 2;
    }
  }
}

.searchbar-form {
  background: $color-background;
  width: 100%;
  @include gt-sm {
    width: 65%;
  }
}

.search-container {
  width: 100%;

  @include gt-sm {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 0;
    transition: width $transition-time linear;

    &.search-input-visibility {
      width: 50%;
    }
  }
}

.sort-container {
  width: 100%;

  @include gt-sm {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 45px;
    transition: width $transition-time linear;

    &.sort-input-visibility {
      width: 30%;
      margin-left: $main-spacing/2;
    }
  }
}

.search-section {
  overflow: hidden;
  flex: 1;
}

.sort-section {
  overflow: hidden;
  flex: 1;
}

.search-bar {
  padding-bottom: $main-spacing/2;
  display: flex;
  flex-direction: column;;
  min-width: 100%;

  @include gt-sm {
    box-shadow: none;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }
}

.search-icon {
  font-size: 1.2rem;
  color: $color-text;
  margin-right: $main-spacing * 0.25;
  margin-left: $main-spacing * 0.25;
}

.search-input {
  font-size: 0.9rem;
}

.search-field {
  margin-bottom: $main-spacing/2;
}

.search-fields {
  width: 100%;
  margin-bottom: $main-spacing / 2;

  &:not(.in-modal) {
    @include gt-sm {
      display: flex;

      .search-field {
        flex: 1;
        margin-bottom: 0;
      }

      .search-query {
        margin-left: $main-spacing / 2;
        flex: 1;
        display: none;

        &.active {
          display: flex;
        }
      }
    }
  }
}

.search-header-cont {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: $main-spacing * 0.25;
  label {
    font-size: 0.8rem;
    font-weight: 700;
  }
}

.search-icon-wrapper {
  display: none;

  @include gt-sm {
    width: 2 * $main-spacing;
    height: 2 * $main-spacing;
    background-color: $color-primary;
    color: $color-background;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $main-spacing/1.6;
    margin-top: 12px;
    margin-left: $main-spacing/2;

    .search-icon {
      color: white;
    }

    &:hover {
      cursor: pointer;
      background-color: lighten($color-primary, 20%);
    }
  }
}
