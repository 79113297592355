@import '../../../config/variables';
@import '../../../config/mixins';

.table-container {
  overflow: auto;
  max-height: 65vh;
}

.table {
  width: 100%;
  font-size: 0.9rem;
  display: table;
  // border-spacing: 0 $main-spacing;
  // border-collapse: separate;
  thead {
    border-bottom: 1px solid $color-primary;

    font-size: 0.75rem;
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
    color: rgba($color-text, 0.5);
  }

  &.secondary {
    // border-top: 2px solid rgba($color-text, 0.75);
    td,
    th {
      width: 200px;
    }

    thead {
      background-color: rgba($color-primary, 0.15);
      color: $color-primary;
      text-transform: none;
      font-size: 0.8rem;
      font-weight: 300;
      border-bottom: none;
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: transparent;
        }
        border-bottom: 2px solid rgba($color-primary, 0.15);
        &:last-child {
          border-bottom: none;
        }

        &.inactive {
          opacity: 0.25;
        }

        &.deleted {
          background-color: rgba(darken($color-danger, 15%), 0.5);
        }
      }
    }
  }

  tbody {
    width: 100%;
    tr {
      // border: 1px solid $color-primary;
      // border-spacing: 0 $main-spacing;
      // box-shadow: 0 0 0px 1px $color-primary inset;

      &:nth-child(odd) {
        background-color: darken($color-background, 10%);
      }
    }
  }

  td,
  th {
    padding: $main-spacing/2 $main-spacing/2;
    text-align: center;
    vertical-align: middle;
  }

  img {
    max-width: 100px;
    max-height: 100px;
  }

  .checkbox {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button {
    margin-left: 5px;
    line-height: normal;
    vertical-align: middle;
    width: auto;
    height: auto;
  }

  .sort {
    font-size: 0.8rem;
    vertical-align: middle;
    cursor: pointer;
    color: $color-text;

    &:first-child {
      margin-left: $main-spacing/5;
    }

    &.active {
      color: $color-primary;
    }
  }

  .alert {
    margin: 0;
  }
}

.list {
  .list-element {
    font-size: 0.8rem;
    margin-bottom: $main-spacing / 2;
    background: $color-background;
    border-radius: $border-radius;
    cursor: pointer;

    &.toggled {
      .list-row {
        max-height: 250px;
        opacity: 1;
      }
    }
  }

  .list-row {
    justify-content: space-between;
    display: flex;
    max-height: 0;
    opacity: 0;
    margin-bottom: $main-spacing/2;
    padding-bottom: $main-spacing/2;
    border-bottom: 1px solid darken($color-background, 5%);
    &:last-child {
      border-bottom: none;
    }

    &.mobile {
      padding: 2px;
      max-height: 250px;
      opacity: 1;
    }
    a {
      font-weight: 100;
    }
  }

  .list-value {
    text-align: right;
    font-size: 0.8rem;
    img {
      max-width: 200px;
      max-height: 200px;
    }
    a {
      font-weight: 500;
      font-family: $main-font;
    }
  }

  .list-buttons {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .button {
      margin-right: $main-spacing/4;
      flex: 1 1 auto;
      width: auto;
      // box-shadow:none;

      &:last-child {
        margin-right: 0;
      }
    }

    padding-bottom: $main-spacing/2;
  }
}

@include gt-md {
  .table {
    th,
    td {
      display: table-cell;
    }
  }
}
.cont {
  display: flex;
}
