@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';
.fields-group {
  @include gt-sm {
    display: flex;
    margin: 0 (-$main-spacing/4);
    flex-wrap: wrap;
    > * {
      padding-left: $main-spacing/4;
      padding-right: $main-spacing/4;
      flex: 1 0 50%;
      @include gt-xs {
        flex: 1;
      }
    }
    > .field-checkbox {
      flex: 0 0 100%;
    }
    > .buttons-container {
      flex: 0 0 100%;
    }

    > .field-textarea {
      flex: 2;
    }
  }
}
