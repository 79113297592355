@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.offer-footer {
  align-items: center;
  flex-direction: column;
  align-items: flex-start;

  .footer-company {
    font-weight: 600;
    padding-bottom: $main-spacing/2;
  }

  @include gt-sm {
    flex-direction: row;
    padding-top: $main-spacing;
    align-items: center;
  }
}

.offer-footer > div, .offer-footer > p {
  padding-top: $main-spacing/2;
}

.offer-footer:first-child {
  padding-top: 0;
}