@import "../../../config/variables";
@import "../../../config/mixins";

.notification-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 100%;
}

@include gt-sm {
  .notification-container {
    width: 350px;
    top: $main-spacing/2;
    bottom: unset;
  }
}
