@import "../../../config/variables.scss";

.update-info-bar {
  background-color: $color-primary;
  padding: $main-spacing * 1.5 $main-spacing * 1.5;
  display: block;
  cursor: pointer;
  font-size: 0.9rem;
  transition: $transition-time background-color;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 9998;
  box-shadow: 0 0 $main-spacing / 2 $main-spacing / 4 rgba(0, 0, 0, 0.1);
  color: $color-text;
  &:hover {
    background-color: darken($color-primary, 10%);
  }

  .update-info-icon {
    margin-right: $main-spacing / 4;
  }
}
