@import '../../../config/variables.scss';

.new-password-wrapper {
  flex: 0 1 50%;
  padding: $main-spacing;
  align-items: center;

  .content-wrapper {
    max-width: 500px;

    .restore-pass-text {
      font-size: 15px;
      padding: $main-spacing*2 0;

      h1 {
        font-size: 2em;
        font-weight: 600;
        padding-bottom: $main-spacing/2;
      }

      p {
        font-size: 1.2em;
      }
    }
  }
}