@import '../../../../config/variables';
@import '../../../../config/mixins';

.main-menu-item {
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  position: relative;
  color: $color-text;
  font-size: 0.8rem;
  transition: $transition-time;
  padding: $main-spacing/1.5;
  text-align: center;
  display: flex;
  text-transform: uppercase;

  @include gt-sm {
    font-size: 0.9rem;
    padding: $main-spacing/1.25;
  }

  &.desktop {
    display: none;

    @include gt-md {
      display: block;
    }
  }

  &.active,
  &:active {
    background: $color-primary-light;
    color: $color-primary;
    border-left: 5px solid $color-primary;
  }

  &:hover {
    background: $color-primary-light;
    color: $color-primary;
  }

  @include gt-md {
    background: transparent;
  }
}

// a.main-menu-item {
//   color: $color-text-light;
//   &:visited {
//     color: $color-text-light;
//     text-decoration: none;
//   }
//   &.active,
//   &:active {
//     background: $color-primary-light;
//     color: $color-primary;
//     border-left: 2px solid $color-primary;
//   }

//   &:hover {
//     color: $color-text-light;
//     padding-left: $main-spacing * 2.5;
//   }

//   &:focus {
//     color: $color-text-light;
//     outline: none;
//   }
// }
