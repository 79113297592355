@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap);
.ripple {
  position: absolute;
  width: 300%;
  height: 300%;
  background-repeat: no-repeat;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-animation: ripple 1s 1;
          animation: ripple 1s 1;
  -webkit-animation-fill-mode: none;
          animation-fill-mode: none;
  z-index: 0;
  background: radial-gradient(10px at center, #cccccc 0%, #cccccc 99%, rgba(255, 255, 255, 0) 100%); }
  .ripple.primary {
    background: radial-gradient(10px at center, #51a6ff 0%, #51a6ff 99%, rgba(0, 114, 234, 0) 100%); }
  .ripple.secondary {
    background: radial-gradient(10px at center, #57762f 0%, #57762f 99%, rgba(140, 186, 81, 0) 100%); }
  .ripple.success {
    background: radial-gradient(10px at center, #0bff91 0%, #0bff91 99%, rgba(0, 164, 90, 0) 100%); }
  .ripple.danger {
    background: radial-gradient(10px at center, #9d0f14 0%, #9d0f14 99%, rgba(234, 40, 47, 0) 100%); }

@-webkit-keyframes ripple {
  from {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1); }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(20);
            transform: translate(-50%, -50%) scale(20); } }

@keyframes ripple {
  from {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1); }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(20);
            transform: translate(-50%, -50%) scale(20); } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.button, .button-primary, .button-secondary, .button-outline, .button-danger, .button-success {
  display: flex;
  letter-spacing: 1px;
  background-color: #fcfcfc;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  line-height: 1.2rem;
  border: none;
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  outline: none;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  min-height: 90%;
  min-height: 50px;
  text-transform: uppercase;
  -webkit-animation-name: show;
          animation-name: show;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-timing-function: ease in;
          animation-timing-function: ease in; }
  @media screen and (min-width: 768px) {
    .button, .button-primary, .button-secondary, .button-outline, .button-danger, .button-success {
      min-width: 300px; } }
  .button:link, .button-primary:link, .button-secondary:link, .button-outline:link, .button-danger:link, .button-success:link, .button:active, .button-primary:active, .button-secondary:active, .button-outline:active, .button-danger:active, .button-success:active, .button:visited, .button-primary:visited, .button-secondary:visited, .button-outline:visited, .button-danger:visited, .button-success:visited {
    color: #fff; }
  .button:hover, .button-primary:hover, .button-secondary:hover, .button-outline:hover, .button-danger:hover, .button-success:hover {
    background: #f2f2f2; }

.button-text {
  text-align: center;
  z-index: 0; }
  @media screen and (max-width: 767px) {
    .button-text.button-icon-only {
      display: none; } }

.button {
  color: #fff; }
  .button:link, .button:active, .button:visited {
    color: #fff; }
  .button:hover {
    background: #f2f2f2; }
  .button.button-reversed {
    min-width: auto;
    min-height: auto;
    background-color: transparent;
    color: #333;
    text-transform: uppercase;
    font-size: 0.9rem;
    padding: 10px;
    box-shadow: none; }
    .button.button-reversed:hover {
      background-color: #e6e6e6; }

.button-primary {
  background-color: #0072ea;
  color: #fff;
  text-transform: uppercase; }
  .button-primary:link, .button-primary:active, .button-primary:visited {
    color: #fff; }
  .button-primary:hover {
    color: #fff;
    background: #057fff; }
  .button-primary.button-reversed {
    color: #0072ea; }
    .button-primary.button-reversed:hover {
      background-color: #ecf3fc; }

.button-secondary {
  background-color: #8cba51;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem; }
  .button-secondary:link, .button-secondary:active, .button-secondary:visited {
    color: #fff; }
  .button-secondary:hover {
    background: #98c163; }
  @media screen and (max-width: 767px) {
    .button-secondary {
      width: 100%; } }

.button-outline {
  background-color: transparent;
  color: #0072ea;
  border: 2px solid #0072ea;
  font-size: 1rem;
  text-transform: uppercase; }
  .button-outline:link, .button-outline:active, .button-outline:visited {
    color: #0072ea; }
  .button-outline:hover {
    background: rgba(0, 114, 234, 0.3);
    color: #0072ea; }

.button-danger {
  background: #ea282f;
  color: #fff; }
  .button-danger:link, .button-danger:active, .button-danger:visited {
    color: #fff; }
  .button-danger:hover {
    background: #ec3f45;
    color: #fff; }
  .button-danger.button-reversed {
    color: #ea282f; }
    .button-danger.button-reversed:hover {
      background-color: #fce2e3; }

.button-success {
  background: #00a45a;
  color: #fff; }
  .button-success:link, .button-success:active, .button-success:visited {
    color: #fff; }
  .button-success:hover {
    background: #00be68;
    color: #fff; }

.button-small {
  min-width: 200px;
  font-size: 1rem;
  min-height: 45px;
  padding: 10px; }

.button-extra-small {
  font-size: 0.8rem;
  min-width: unset;
  min-height: unset; }

.button-big {
  padding: 20px 100px;
  font-size: 1.05rem; }

.button-disabled {
  background: #aaa;
  opacity: 0.5;
  color: #fff;
  cursor: not-allowed; }
  .button-disabled:hover {
    background: #aaa;
    color: #fff; }

.button-icon {
  display: flex;
  align-items: center; }

.button-left-icon .button-icon {
  height: 1.5rem;
  margin: 0 10px 0 0; }

.button-left-icon.button-no-text .button-icon {
  margin: 0; }

.button-right-icon .button-icon {
  height: 1.5rem;
  margin: 0 0 0 10px; }

.button-right-icon.button-no-text .button-icon {
  margin: 0; }

.button-link {
  min-width: auto;
  box-shadow: none;
  color: #0072ea;
  background-color: transparent;
  display: flex;
  justify-content: flex-start; }
  .button-link:link, .button-link:visited {
    color: #0072ea; }
  .button-link:hover {
    color: #0072ea;
    background-color: transparent; }
  .button-link:active {
    color: #0072ea; }

.required-indicator {
  font-weight: 700;
  margin-left: 2px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.input-container,
.form-field {
  min-width: 80px;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left; }
  .input-container ::-webkit-input-placeholder, .form-field ::-webkit-input-placeholder {
    color: #dcdde7; }
  .input-container :-ms-input-placeholder, .form-field :-ms-input-placeholder {
    color: #dcdde7; }
  .input-container ::placeholder,
  .form-field ::placeholder {
    color: #dcdde7; }
  .input-container.nomargin,
  .form-field.nomargin {
    margin-bottom: 0; }
    .input-container.nomargin .input-wrapper,
    .form-field.nomargin .input-wrapper {
      margin-bottom: 0; }
  .input-container.hidden,
  .form-field.hidden {
    display: none;
    border: 1px solid pink; }
  .input-container label,
  .form-field label {
    font-size: 0.9rem;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-family: "Source Sans Pro", sans-serif; }
  .input-container input[readonly],
  .form-field input[readonly] {
    background: #e6e6e6; }
  .input-container.compact label,
  .form-field.compact label {
    font-size: 0.75rem;
    margin-bottom: 0; }
  .input-container.compact input,
  .form-field.compact input {
    font-size: 0.7rem;
    padding: 10px;
    height: 40px; }
  .input-container.rounded input,
  .form-field.rounded input {
    border-radius: 5px; }
  .input-container.input-with-icon input,
  .form-field.input-with-icon input {
    padding-right: 1.5rem; }

.dark-background {
  background: #fff;
  border: none; }

.input-wrapper {
  position: relative;
  margin-bottom: 5px; }

.input-group {
  display: flex;
  align-items: center; }

.input-icon {
  font-weight: 400;
  font-size: 0.9rem;
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  color: #333; }

.text-danger {
  color: #ea282f;
  font-size: 0.8rem; }

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 60px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 300; }
  .checkbox-container.dark-label {
    color: #333; }

.chekbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid #0072ea; }

.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: #0072ea; }

.checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none; }

.checkbox-label-label {
  margin-left: -30px; }

.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block; }

.checkbox-container .checkbox-checkmark:after {
  left: 5px;
  top: 3px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

[type='checkbox'] {
  display: none; }

.radio-container {
  display: block;
  position: relative;
  padding-left: 60px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 300; }
  .radio-container.dark-label {
    color: #333; }

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid #0072ea;
  border-radius: 50%; }

.radio-container input:checked ~ .radio-checkmark {
  background-color: #0072ea; }

.radio-checkmark:after {
  content: '';
  position: absolute;
  display: none; }

.radio-label-label {
  margin-left: -30px; }

.radio-container input:checked ~ .radio-checkmark:after {
  display: block; }

.radio-container .radio-checkmark:after {
  left: 5px;
  top: 3px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

[type='radio'] {
  display: none; }

/*.select-label {
  width: 100%;
  display: block;
  font-size: 0.9rem;
  select {
    width: 100%;
    height: $main-spacing * 4;
    border-radius: 7px;
    font-family: $main-font;
    font-size: 1.1rem;
    border: 1px solid $color-text-alter;
    background: $color-background;
    appearance: none;
    padding: $main-spacing;
  }
}*/
.select-label-text {
  color: #333;
  padding-bottom: 10px;
  padding-top: 10px;
  display: block; }

.text-input-container {
  width: 100%; }
  .text-input-container input {
    width: 100%;
    height: 80px;
    border-radius: 7px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.1rem;
    border: 1px solid #333;
    -webkit-appearance: none;
            appearance: none;
    padding: 20px; }

.checkbox-outer-cont {
  margin-bottom: 10px; }

.toolbar-input {
  width: 100%;
  border: none;
  background-color: #ebebeb;
  font-size: 0.9rem;
  font-weight: 100;
  padding: 10px;
  color: #333;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 0;
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.05);
  height: 40px; }
  .toolbar-input ::-webkit-input-placeholder {
    color: #333; }
  .toolbar-input :-ms-input-placeholder {
    color: #333; }
  .toolbar-input ::placeholder {
    color: #333; }

.alert:not(.alert-simple) {
  font-family: "Source Sans Pro", sans-serif;
  margin: 20px 0;
  outline: none;
  font-weight: 500;
  padding: 13.33333px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center; }
  .alert:not(.alert-simple).alert-error {
    background: #ea282f; }
  .alert:not(.alert-simple).alert-notice {
    background: #7d7d78; }
  .alert:not(.alert-simple).alert-success {
    background: #00a45a; }
  .alert:not(.alert-simple).list {
    background: #fff;
    border: 1px solid #ea282f;
    color: #ea282f; }

.alert-icon {
  margin-right: 10px;
  font-size: 1.2rem; }

.alert.alert-simple {
  display: flex;
  align-items: center; }
  .alert.alert-simple.alert-error {
    color: #ea282f; }
  .alert.alert-simple.alert-notice {
    color: #7d7d78; }

.alert.notification {
  box-shadow: 10px 0 20px -20px rgba(0, 0, 0, 0.15); }

/*.alert {
  &.list {
    background: $color-background-box;
    border: 1px solid $color-danger;
    color: $color-danger;
  }
}*/

.spinner-container {
  min-height: 300px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; }
  .spinner-container.overlay {
    position: absolute;
    min-height: auto; }
  .spinner-container.half-transparent {
    background: rgba(255, 255, 255, 0.5); }
  .spinner-container.transparent {
    background: transparent; }
  .spinner-container.dark {
    background: #fff; }
  .spinner-container .spinner {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 4px solid #0072ea;
    border-radius: 50%;
    -webkit-animation: spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0072ea transparent transparent transparent; }
    .spinner-container .spinner:nth-child(1) {
      -webkit-animation-delay: -0.45s;
              animation-delay: -0.45s; }
    .spinner-container .spinner:nth-child(2) {
      -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s; }
    .spinner-container .spinner:nth-child(3) {
      -webkit-animation-delay: -0.15s;
              animation-delay: -0.15s; }
  .spinner-container.small .spinner {
    width: 20px;
    height: 20px; }

@-webkit-keyframes spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.file-wrapper {
  border: 1px dashed #0072ea;
  padding: 30px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.1); }
  .file-wrapper:hover {
    background-color: rgba(0, 114, 234, 0.1); }
  .file-wrapper img {
    max-height: 350px;
    max-width: 350px;
    width: 100%;
    object-fit: contain; }
  .file-wrapper p {
    margin-top: 20px;
    font-size: 0.9rem;
    word-break: break-all; }
  .file-wrapper input {
    display: none; }

.form-field-title,
.input-name {
  font-size: 0.9rem;
  margin-bottom: 10px;
  display: block; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
  -webkit-animation-name: showModal;
          animation-name: showModal;
  -webkit-animation-duration: 0.15s;
          animation-duration: 0.15s;
  transition: opacity 0.15s ease-in-out;
  opacity: 0; }
  .modal.visible {
    opacity: 1; }
    .modal.visible .modal-body {
      -webkit-transform: scale(1);
              transform: scale(1); }
  .modal .modal-wrapper {
    width: 100%;
    height: 100%; }
  .modal .modal-body {
    position: relative;
    z-index: 1000;
    overflow: auto;
    height: 90vh;
    max-width: 90%;
    width: 100%;
    position: relative;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    transition: -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    .modal .modal-body p {
      max-width: 500px;
      font-size: 0.9rem; }
  .modal.full-screen .modal-body {
    max-width: 100%;
    width: 100%;
    height: 100%; }

@media screen and (min-width: 576px) {
  .modal .modal-body {
    width: auto; } }

.logo-wrapper {
  width: 150px;
  height: auto; }
  .logo-wrapper.logo-big {
    width: 200px; }

.app-status {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  color: #333; }
  .app-status .app-status-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333; }

.nowifi-icon {
  fill: #333;
  width: 80px;
  height: 80px;
  margin: 40px 0; }

.offline-logo-container {
  width: 80vw;
  max-width: 300px; }

.update-info-bar {
  background-color: #0072ea;
  padding: 30px 30px;
  display: block;
  cursor: pointer;
  font-size: 0.9rem;
  transition: 0.3s background-color;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 9998;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  color: #333; }
  .update-info-bar:hover {
    background-color: #0059b7; }
  .update-info-bar .update-info-icon {
    margin-right: 5px; }

.flatpickr-monthDropdown-months {
  display: inline-block; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.container {
  margin: 0px auto;
  padding: 10px;
  padding-bottom: 40px;
  width: 100%;
  max-width: 1300px; }
  .container.narrow {
    max-width: 1040px; }
  .container.fluid {
    max-width: 100%; }
  @media screen and (min-width: 768px) {
    .container {
      padding: 20px;
      padding-bottom: 80px; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.buttons-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  flex-direction: column; }
  .buttons-container .button {
    width: 100%;
    margin-bottom: 10px; }
    .buttons-container .button:last-child {
      margin-bottom: 0; }
  .buttons-container.max {
    justify-content: stretch;
    flex: 1 1; }
    .buttons-container.max .button {
      flex: 1 1; }
  .buttons-container.center {
    justify-content: center; }
  .buttons-container.baseline {
    align-items: baseline; }
  .buttons-container.padding {
    padding-top: 60px; }
  @media screen and (min-width: 768px) {
    .buttons-container:not(.block) {
      flex-direction: row; }
      .buttons-container:not(.block) .button {
        margin-right: 10px;
        width: auto; }
        .buttons-container:not(.block) .button:last-child {
          margin-right: 0; } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .buttons-container:not(.block) .button {
      margin-bottom: 0; } }
  .buttons-container.tabs {
    width: auto;
    flex-wrap: nowrap; }
    @media screen and (max-width: 767px) {
      .buttons-container.tabs {
        flex-direction: row; }
        .buttons-container.tabs .button {
          margin: 0; } }
  .buttons-container.margin-top {
    margin-top: 20px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%; }
  @media screen and (min-width: 920px) {
    .bottom-container {
      position: relative; } }
  .bottom-container .bottom-wrapper {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%; }
  .bottom-container .button {
    margin-bottom: 10px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.form-container {
  flex: 1 1;
  position: relative;
  max-width: 500px;
  margin: 0px auto;
  width: 100%; }
  .form-container.full {
    max-width: unset; }
  .form-container form {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%; }
    .form-container form .form-buttons {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
  @media screen and (min-width: 768px) {
    .form-container {
      margin: 0; }
      .form-container form {
        display: block; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.app-wrapper {
  display: flex;
  padding-top: 50px;
  height: 100%;
  min-height: 100vh; }
  .app-wrapper.trial {
    padding-top: 120px; }
  .app-wrapper.not-logged {
    padding-top: 50px; }
  .app-wrapper.full {
    padding-top: 0; }
  @media screen and (min-width: 920px) {
    .app-wrapper {
      padding-top: 70px; }
      .app-wrapper.trial {
        padding-top: 140px; }
      .app-wrapper.not-logged {
        padding-top: 70px; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.logo-container {
  text-align: center;
  display: flex;
  padding: 20px 0;
  width: 100%; }
  @media screen and (max-width: 919px) {
    .logo-container.hidden {
      display: none; } }
  .logo-container.no-padding {
    padding: 0;
    width: auto; }
  .logo-container.to-right {
    justify-content: flex-end; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.wrapper {
  display: flex;
  width: 100%; }
  .wrapper.wrapper-column {
    flex-direction: column; }
  .wrapper.wrapper-primary {
    background-color: #0072ea;
    margin: 0 -20px;
    color: #fff;
    width: auto;
    padding: 20px; }
    .wrapper.wrapper-primary p {
      color: #fff; }
    @media screen and (min-width: 768px) {
      .wrapper.wrapper-primary {
        padding: 30px;
        margin: 0 -30px; } }

.bottom-links {
  font-size: 0.9rem;
  padding-bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-end; }
  .bottom-links a {
    color: #0072ea;
    font-weight: 400;
    display: block; }
    .bottom-links a:hover {
      color: #51a6ff;
      cursor: pointer; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

main {
  min-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%; }
  main.stretch {
    display: flex;
    justify-content: space-between; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

@media screen and (max-width: 919px) {
  .login-wrapper {
    flex-direction: column-reverse; } }

.login-wrapper .login-section {
  flex: 0 1 50%;
  padding: 20px;
  align-items: center; }
  .login-wrapper .login-section .content-wrapper {
    max-width: 500px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

@media screen and (min-width: 768px) {
  .fields-group {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap; }
    .fields-group > * {
      padding-left: 5px;
      padding-right: 5px;
      flex: 1 0 50%; } }
    @media screen and (min-width: 768px) and (min-width: 576px) {
      .fields-group > * {
        flex: 1 1; } }

@media screen and (min-width: 768px) {
    .fields-group > .field-checkbox {
      flex: 0 0 100%; }
    .fields-group > .buttons-container {
      flex: 0 0 100%; }
    .fields-group > .field-textarea {
      flex: 2 1; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.section-header-wrapper {
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px; }
  .section-header-wrapper.no-margin-top {
    margin-top: 0; }
  @media screen and (min-width: 768px) {
    .section-header-wrapper {
      flex-direction: row; } }
  .section-header-wrapper .section-title {
    font-weight: 600;
    font-size: 1.3rem; }
    @media screen and (min-width: 920px) {
      .section-header-wrapper .section-title {
        padding-left: 0; } }
    @media screen and (max-width: 767px) {
      .section-header-wrapper .section-title {
        width: 100%;
        display: flex;
        margin-bottom: 10px; } }

.subtitle-header {
  font-size: 1.1rem;
  color: #0072ea;
  font-style: italic;
  font-weight: 500;
  padding: 0 0 30px 0; }

.field-options-container {
  position: absolute;
  width: 100%;
  left: 0;
  background-color: rgba(51, 51, 51, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999; }

.field-options-select {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.15); }

.single-block {
  padding: 10px;
  font-size: 0.8rem;
  background: #cccccc;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  margin: 0 5px;
  transition: 0.3s;
  margin-bottom: 10px;
  flex: 0 0 auto;
  cursor: pointer;
  min-height: 50px;
  min-width: 50px; }
  .single-block .text-group {
    display: flex;
    flex-direction: column; }
    .single-block .text-group .input-container {
      min-width: 200px; }
  .single-block.dragging {
    cursor: move; }
  .single-block.group:hover {
    background: #0072ea; }
  .single-block.active-dropzone {
    box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.15);
    background: #51a6ff;
    border: 1px solid #0072ea; }
  .single-block .content {
    flex: 1 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700; }
    .single-block .content > .single-block {
      margin-bottom: 0; }

.delete-block {
  cursor: pointer;
  border: none;
  background: none;
  font-size: 0.7rem;
  background: #ea282f;
  color: #fff;
  padding: 5px;
  margin-left: 5px;
  margin-right: 2.5px; }

.group {
  background: #b3b3b3;
  border: 1px dashed #333;
  min-width: 80px; }

.graph-label {
  font-size: 0.9rem;
  display: block;
  margin-bottom: 5px; }

.graph-area {
  border: 1px solid #333;
  min-height: 100px;
  padding: 5px;
  padding-top: 13.33333px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  align-items: center;
  align-content: flex-start;
  cursor: pointer;
  overflow: auto; }
  .graph-area.active-dropzone {
    box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.15);
    border: 1px solid #0072ea; }

.graph-editor {
  margin-bottom: 20px;
  background: #fff; }
  .graph-editor.full-screen {
    padding: 20px; }
    .graph-editor.full-screen .graph-area {
      min-height: 500px; }

.graph-header {
  display: flex;
  justify-content: space-between; }

.graph-buttons {
  display: flex;
  flex-wrap: wrap;
  position: relative; }
  .graph-buttons .button {
    margin: 3.33333px; }

.field {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.hello-text-wrapper {
  display: flex;
  flex-direction: column;
  padding: 50px 0; }
  @media screen and (min-width: 768px) {
    .hello-text-wrapper .mobile-hello-text {
      display: none; } }
  .hello-text-wrapper .header-hello-text {
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 10px; }
  .hello-text-wrapper .subtitle-hello-text {
    font-size: 1.3rem; }
  @media screen and (max-width: 919px) {
    .hello-text-wrapper.hidden {
      display: none; } }
  .hello-text-wrapper.mobile-style {
    position: absolute;
    bottom: 0;
    padding: 20px; }
    .hello-text-wrapper.mobile-style .header-hello-text {
      color: #fff;
      font-size: 1.6rem;
      font-weight: 600; }
    .hello-text-wrapper.mobile-style .subtitle-hello-text {
      color: #fff;
      font-size: 1.2rem; }

.icon-wrapper .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem; }
  .icon-wrapper .icon svg {
    width: 30px;
    height: 30px;
    fill: #8cba51;
    transition: fill 0.3s; }

.icon-wrapper.big .icon svg {
  width: 40px;
  height: 40px; }

.icon-wrapper.small .icon svg {
  width: 20px;
  height: 20px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.home-circle-wrapper {
  position: relative;
  display: flex;
  flex: 0 1 50%;
  background-color: #0072ea;
  overflow: hidden; }
  .home-circle-wrapper .circle {
    position: absolute;
    width: 950px;
    height: 950px;
    border-radius: 50%;
    background-color: #fff; }
    .home-circle-wrapper .circle.first {
      top: 5%;
      opacity: 0.1;
      left: -50%; }
    .home-circle-wrapper .circle.second {
      bottom: -40%;
      opacity: 0.15;
      left: -5%; }
    @media screen and (max-width: 919px) {
      .home-circle-wrapper .circle {
        width: 450px;
        height: 450px; }
        .home-circle-wrapper .circle.first {
          top: -15%; } }
  .home-circle-wrapper .profile-circle {
    min-height: 20vh;
    display: flex;
    align-items: flex-end; }
    @media screen and (min-width: 920px) {
      .home-circle-wrapper .profile-circle {
        min-height: 10%; } }
  @media screen and (min-width: 768px) {
    .home-circle-wrapper .mobile-hello-text {
      display: none; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.main-menu-item {
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  position: relative;
  color: #333;
  font-size: 0.8rem;
  transition: 0.3s;
  padding: 13.33333px;
  text-align: center;
  display: flex;
  text-transform: uppercase; }
  @media screen and (min-width: 768px) {
    .main-menu-item {
      font-size: 0.9rem;
      padding: 16px; } }
  .main-menu-item.desktop {
    display: none; }
    @media screen and (min-width: 920px) {
      .main-menu-item.desktop {
        display: block; } }
  .main-menu-item.active, .main-menu-item:active {
    background: #ecf3fc;
    color: #0072ea;
    border-left: 5px solid #0072ea; }
  .main-menu-item:hover {
    background: #ecf3fc;
    color: #0072ea; }
  @media screen and (min-width: 920px) {
    .main-menu-item {
      background: transparent; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.menu-main {
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  justify-items: flex-start; }
  .menu-main .main-menu-content {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    flex-grow: 2; }
  .menu-main .menu-desktop {
    display: none; }
    @media screen and (min-width: 920px) {
      .menu-main .menu-desktop {
        display: block; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.sidebar {
  background-color: #fff;
  flex: 0 0 80%;
  position: fixed;
  overflow: hidden;
  width: 80%;
  box-shadow: 0 0 50px -10px rgba(0, 0, 0, 0.15);
  margin-left: -110%;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  transition: 0.3s margin-left;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  overflow: auto; }
  .sidebar .sidebar-logo {
    display: none; }
  .sidebar.toggled {
    margin-left: 0; }
  @media screen and (min-width: 920px) {
    .sidebar {
      position: relative;
      height: 100%;
      width: 304px;
      flex: 0 0 304px;
      font-size: 1rem;
      margin-left: 0; }
      .sidebar .sidebar-logo {
        display: block;
        padding: 0 20px; } }

.sidebar-toggle {
  position: absolute;
  top: 10px;
  left: 10px; }

.sidebar-container {
  overflow: auto;
  position: relative; }
  @media screen and (min-width: 920px) {
    .sidebar-container {
      position: fixed;
      width: 304px; } }

.sidebar-shadow {
  transition: 0.3s; }
  .sidebar-shadow.toggled {
    position: fixed;
    background: rgba(51, 51, 51, 0.5);
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    right: 0; }
  @media screen and (min-width: 920px) {
    .sidebar-shadow {
      display: none; } }

.sidebar-button {
  padding: 20px;
  display: flex; }
  @media screen and (min-width: 920px) {
    .sidebar-button {
      display: none; } }
  .sidebar-button .close-button {
    color: #333;
    box-shadow: none;
    justify-content: flex-end; }
    .sidebar-button .close-button:hover {
      background-color: #fff;
      cursor: pointer;
      color: #0072ea; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.menu-toggle {
  width: 30px;
  height: 18px;
  position: relative;
  display: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer; }
  .menu-toggle .line {
    display: block;
    background: #0072ea;
    width: 30px;
    height: 3px;
    position: absolute;
    left: 0;
    border-radius: 1.5px;
    transition: all 0.3s; }
    .menu-toggle .line.line-1 {
      top: 0; }
    .menu-toggle .line.line-2 {
      top: 50%; }
    .menu-toggle .line.line-3 {
      top: 100%; }
  .menu-toggle:hover .line-1, .menu-toggle:focus .line-1 {
    -webkit-transform: translateY(-1.5px);
            transform: translateY(-1.5px); }
  .menu-toggle:hover .line-3, .menu-toggle:focus .line-3 {
    -webkit-transform: translateY(1.5px);
            transform: translateY(1.5px); }
  .menu-toggle.toggled .line {
    background: #fff; }
  .menu-toggle.toggled .line-1 {
    -webkit-transform: translateY(9px) translateX(0) rotate(45deg);
            transform: translateY(9px) translateX(0) rotate(45deg); }
  .menu-toggle.toggled .line-2 {
    opacity: 0; }
  .menu-toggle.toggled .line-3 {
    -webkit-transform: translateY(-9px) translateX(0) rotate(-45deg);
            transform: translateY(-9px) translateX(0) rotate(-45deg); }
  @media screen and (max-width: 919px) {
    .menu-toggle {
      display: block;
      z-index: 1; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.header-wrapper {
  align-items: center;
  display: block; }
  @media screen and (max-width: 767px) {
    .header-wrapper {
      padding-bottom: 20px; } }
  @media screen and (min-width: 768px) {
    .header-wrapper {
      display: flex;
      justify-content: space-between; } }
  .header-wrapper .header-main {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    @media screen and (min-width: 768px) {
      .header-wrapper .header-main {
        margin-bottom: 0; } }
  .header-wrapper .section-header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem; }
    @media screen and (max-width: 919px) {
      .header-wrapper .section-header {
        padding-left: 20px; } }
    @media screen and (min-width: 768px) {
      .header-wrapper .section-header {
        font-size: 1.5rem; } }
    @media screen and (min-width: 920px) {
      .header-wrapper .section-header {
        font-size: 1.8rem; } }

.header-additional {
  display: flex; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.card {
  width: 100%;
  background-color: #fff; }
  @media screen and (max-width: 919px) {
    .card {
      margin: 0; } }

.divider {
  width: 100%;
  margin: 20px 0;
  border-bottom: 2px solid #0072ea; }
  .divider.light {
    border-bottom: 2px solid #fff; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.product-description {
  background-color: #ecf3fc;
  padding: 10px 20px;
  font-size: 0.9rem; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.profile-details {
  padding: 10px; }
  .profile-details .user-mail {
    color: #fff;
    font-style: italic;
    font-size: 0.85rem; }
  @media screen and (min-width: 768px) {
    .profile-details {
      padding: 30px 20px; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.profile-circle-wrapper {
  position: relative;
  display: flex;
  flex: 0 1 50%;
  background-color: #0072ea;
  overflow: hidden; }
  .profile-circle-wrapper .circle {
    position: absolute;
    width: 950px;
    height: 950px;
    border-radius: 50%;
    background-color: #fff; }
    .profile-circle-wrapper .circle.first {
      top: 5%;
      opacity: 0.1;
      left: -50%; }
    .profile-circle-wrapper .circle.second {
      bottom: -40%;
      opacity: 0.15;
      left: -5%; }
    @media screen and (max-width: 919px) {
      .profile-circle-wrapper .circle {
        width: 450px;
        height: 450px; }
        .profile-circle-wrapper .circle.first {
          top: -15%; } }
  .profile-circle-wrapper .profile-circle {
    min-height: 15vh;
    display: flex;
    align-items: flex-end; }
    @media screen and (min-width: 920px) {
      .profile-circle-wrapper .profile-circle {
        min-height: 10%; } }
  @media screen and (min-width: 768px) {
    .profile-circle-wrapper .mobile-hello-text {
      display: none; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.field-box {
  border: 1px solid #0072ea;
  background-color: #ecf3fc;
  margin-right: 10px;
  margin-bottom: 10px; }
  .field-box .field-text {
    color: #0072ea;
    text-transform: uppercase;
    padding: 5px 10px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.info-paragraph {
  padding: 30px 0; }
  .info-paragraph.no-margin {
    padding: 0; }
  .info-paragraph.tab {
    color: #0072ea;
    font-size: 1.1rem;
    font-weight: 600; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.detail-line {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebebeb;
  font-size: 0.9rem; }
  .detail-line:last-child {
    margin-bottom: 0;
    border-bottom: none; }
  .detail-line .details-title {
    padding-right: 10px; }
  .detail-line .details-data {
    font-weight: 600;
    display: block; }
  .detail-line.break-line span {
    display: block; }
  .detail-line.important .details-title {
    font-weight: 500; }
  .detail-line.important .details-data {
    font-weight: 600;
    color: #0072ea; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.searchbar-wrapper {
  position: relative;
  min-width: 100%;
  background-color: #333;
  border: 1px solid #333;
  border-radius: 40px;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  margin-top: 40px;
  display: flex; }
  .searchbar-wrapper .button-search {
    margin: 4px;
    padding-left: 20px;
    padding-right: 20px; }
    @media screen and (min-width: 576px) {
      .searchbar-wrapper .button-search {
        padding-left: 40px;
        padding-right: 40px; } }

.searchbar-form {
  background: #fff;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .searchbar-form {
      width: 65%; } }

.search-container {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .search-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 0;
      transition: width 0.3s linear; }
      .search-container.search-input-visibility {
        width: 50%; } }

.sort-container {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .sort-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 45px;
      transition: width 0.3s linear; }
      .sort-container.sort-input-visibility {
        width: 30%;
        margin-left: 10px; } }

.search-section {
  overflow: hidden;
  flex: 1 1; }

.sort-section {
  overflow: hidden;
  flex: 1 1; }

.search-bar {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  min-width: 100%; }
  @media screen and (min-width: 768px) {
    .search-bar {
      box-shadow: none;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row; } }

.search-icon {
  font-size: 1.2rem;
  color: #333;
  margin-right: 5px;
  margin-left: 5px; }

.search-input {
  font-size: 0.9rem; }

.search-field {
  margin-bottom: 10px; }

.search-fields {
  width: 100%;
  margin-bottom: 10px; }
  @media screen and (min-width: 768px) {
    .search-fields:not(.in-modal) {
      display: flex; }
      .search-fields:not(.in-modal) .search-field {
        flex: 1 1;
        margin-bottom: 0; }
      .search-fields:not(.in-modal) .search-query {
        margin-left: 10px;
        flex: 1 1;
        display: none; }
        .search-fields:not(.in-modal) .search-query.active {
          display: flex; } }

.search-header-cont {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px; }
  .search-header-cont label {
    font-size: 0.8rem;
    font-weight: 700; }

.search-icon-wrapper {
  display: none; }
  @media screen and (min-width: 768px) {
    .search-icon-wrapper {
      width: 40px;
      height: 40px;
      background-color: #0072ea;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 12.5px;
      margin-top: 12px;
      margin-left: 10px; }
      .search-icon-wrapper .search-icon {
        color: white; }
      .search-icon-wrapper:hover {
        cursor: pointer;
        background-color: #51a6ff; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.pagination {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  font-weight: 300;
  flex-wrap: wrap; }
  .pagination li {
    margin: 10px 5px; }
  @media screen and (min-width: 768px) {
    .pagination li {
      margin: 0 10px; }
    .pagination .previous,
    .pagination .next {
      width: auto; } }
  .pagination .button {
    padding: 0 20px;
    outline: none;
    min-width: unset;
    min-height: unset;
    height: 40px; }
  .pagination .button-link {
    background-color: rgba(140, 186, 81, 0.25);
    color: #fff; }
  .pagination .button-link-active {
    border: 1px solid #8cba51;
    color: #fff;
    background: #8cba51;
    font-weight: 700; }
  .pagination .disabled .button {
    background-color: rgba(255, 255, 255, 0.5);
    color: rgba(51, 51, 51, 0.25);
    cursor: not-allowed;
    box-shadow: none; }


.tabs-container {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 0 50px -10px rgba(0, 0, 0, 0.15); }
  .tabs-container.auto {
    width: auto; }

.tabs-option {
  flex: 1 1;
  text-align: center;
  padding: 40px 20px;
  background: #fff;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  outline: none;
  cursor: pointer; }
  .tabs-option:link, .tabs-option:visited, .tabs-option:hover, .tabs-option:active {
    color: #333; }
  .tabs-option.active {
    border-bottom: 2px solid #8cba51; }

.api-list {
  max-height: 70vh;
  overflow: auto; }

.api-list-header {
  font-size: 0.8rem;
  display: flex;
  margin-bottom: 20px;
  font-weight: 700; }

.subtitle {
  font-size: 1.2rem;
  color: #0072ea;
  font-style: italic;
  font-weight: 500;
  padding: 13.33333px 0 20px 0; }

.table-item {
  display: flex; }
  .table-item .element {
    margin-right: 10px;
    flex: 0 0 100px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.table-container {
  overflow: auto;
  max-height: 65vh; }

.table {
  width: 100%;
  font-size: 0.9rem;
  display: table; }
  .table thead {
    border-bottom: 1px solid #0072ea;
    font-size: 0.75rem;
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
    color: rgba(51, 51, 51, 0.5); }
  .table.secondary td,
  .table.secondary th {
    width: 200px; }
  .table.secondary thead {
    background-color: rgba(0, 114, 234, 0.15);
    color: #0072ea;
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 300;
    border-bottom: none; }
  .table.secondary tbody tr {
    border-bottom: 2px solid rgba(0, 114, 234, 0.15); }
    .table.secondary tbody tr:nth-child(odd) {
      background-color: transparent; }
    .table.secondary tbody tr:last-child {
      border-bottom: none; }
    .table.secondary tbody tr.inactive {
      opacity: 0.25; }
    .table.secondary tbody tr.deleted {
      background-color: rgba(180, 18, 23, 0.5); }
  .table tbody {
    width: 100%; }
    .table tbody tr:nth-child(odd) {
      background-color: #e6e6e6; }
  .table td,
  .table th {
    padding: 10px 10px;
    text-align: center;
    vertical-align: middle; }
  .table img {
    max-width: 100px;
    max-height: 100px; }
  .table .checkbox {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .table .button {
    margin-left: 5px;
    line-height: normal;
    vertical-align: middle;
    width: auto;
    height: auto; }
  .table .sort {
    font-size: 0.8rem;
    vertical-align: middle;
    cursor: pointer;
    color: #333; }
    .table .sort:first-child {
      margin-left: 4px; }
    .table .sort.active {
      color: #0072ea; }
  .table .alert {
    margin: 0; }

.list .list-element {
  font-size: 0.8rem;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer; }
  .list .list-element.toggled .list-row {
    max-height: 250px;
    opacity: 1; }

.list .list-row {
  justify-content: space-between;
  display: flex;
  max-height: 0;
  opacity: 0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2; }
  .list .list-row:last-child {
    border-bottom: none; }
  .list .list-row.mobile {
    padding: 2px;
    max-height: 250px;
    opacity: 1; }
  .list .list-row a {
    font-weight: 100; }

.list .list-value {
  text-align: right;
  font-size: 0.8rem; }
  .list .list-value img {
    max-width: 200px;
    max-height: 200px; }
  .list .list-value a {
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif; }

.list .list-buttons {
  flex: 1 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 10px; }
  .list .list-buttons .button {
    margin-right: 5px;
    flex: 1 1 auto;
    width: auto; }
    .list .list-buttons .button:last-child {
      margin-right: 0; }

@media screen and (min-width: 920px) {
  .table th,
  .table td {
    display: table-cell; } }

.cont {
  display: flex; }

.thumbnail {
  max-width: 150px; }

.language-selector {
  display: flex;
  justify-content: flex-end;
  margin: 0 -5px; }

.language-flag-container {
  margin: 5px; }

.language-flag {
  width: 2rem;
  border: 1px solid #eee;
  padding: 0;
  cursor: pointer; }
  .language-flag img {
    display: block; }
  .language-flag:hover {
    border: 1px solid rgba(0, 114, 234, 0.5); }
  .language-flag.active {
    border: 1px solid #0072ea;
    box-shadow: 0 0 10px 0 rgba(0, 114, 234, 0.5); }

.offers-cart-button {
  min-width: unset;
  padding-left: 20px;
  padding-right: 20px; }

.ratio-container {
  display: flex;
  font-size: 0.9rem;
  background-color: #0072ea;
  padding: 10px;
  color: #fff; }
  .ratio-container.inside {
    padding: 0; }
  .ratio-container .ratio-value {
    font-weight: 700;
    margin-left: 5px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.notification {
  -webkit-animation-name: show;
          animation-name: show;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  cursor: pointer;
  opacity: 0.99;
  z-index: 999; }
  .notification .alert {
    margin: 0;
    border-radius: 0;
    padding: 20px; }
  @media screen and (min-width: 768px) {
    .notification {
      padding: 5px 20px; }
      .notification .alert {
        padding: 13.33333px;
        margin: 0; } }

@-webkit-keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.notification-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 100%; }

@media screen and (min-width: 768px) {
  .notification-container {
    width: 350px;
    top: 10px;
    bottom: unset; } }

.email-prompt-wrapper {
  flex: 0 1 50%;
  padding: 20px;
  align-items: center; }
  .email-prompt-wrapper .content-wrapper {
    max-width: 500px; }
    .email-prompt-wrapper .content-wrapper .restore-pass-text {
      font-size: 15px;
      padding-bottom: 20px; }
      .email-prompt-wrapper .content-wrapper .restore-pass-text h1 {
        font-size: 2em;
        font-weight: 600;
        padding-bottom: 10px; }
      .email-prompt-wrapper .content-wrapper .restore-pass-text p {
        font-size: 1.2em; }

.new-password-wrapper {
  flex: 0 1 50%;
  padding: 20px;
  align-items: center; }
  .new-password-wrapper .content-wrapper {
    max-width: 500px; }
    .new-password-wrapper .content-wrapper .restore-pass-text {
      font-size: 15px;
      padding: 40px 0; }
      .new-password-wrapper .content-wrapper .restore-pass-text h1 {
        font-size: 2em;
        font-weight: 600;
        padding-bottom: 10px; }
      .new-password-wrapper .content-wrapper .restore-pass-text p {
        font-size: 1.2em; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.tab-wrapper {
  margin-bottom: 20px; }
  .tab-wrapper.no-margin {
    margin-bottom: 0; }
  .tab-wrapper .tab-content {
    justify-content: space-between;
    border: 1px solid #0072ea;
    padding: 5px 20px;
    align-items: center;
    display: flex;
    width: 100%; }
    .tab-wrapper .tab-content .tab-name.bold {
      font-size: 1.2rem;
      font-weight: 700; }
  .tab-wrapper .icon-background {
    background-color: #0072ea;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 12.5px; }
  .tab-wrapper .item-position {
    background-color: #004084;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 12.5px;
    font-weight: 700; }
  .tab-wrapper .field-type {
    display: flex;
    align-items: center; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.modal-content {
  position: relative;
  z-index: 1;
  padding: 10px;
  transition: 0.3s;
  box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  background-color: #fff;
  height: auto;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .modal-content {
      min-width: 500px; } }

.modal-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 0;
  padding-top: 5px;
  padding-bottom: 5px; }

.modal-header {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: 10px; }

.modal-exit {
  color: #0072ea;
  font-size: 2.5rem;
  transition: 0.3s color;
  cursor: pointer;
  line-height: 2.5rem;
  position: absolute;
  top: 5px;
  right: 5px; }
  .modal-exit.navigation {
    top: 10px;
    right: 10px; }
  .modal-exit:hover {
    color: #51a6ff; }

.option-remove {
  flex: 0 1 auto;
  height: 50px;
  padding: 20px;
  min-width: unset;
  margin-top: calc(0.9rem - 5px);
  margin-left: 10px; }

.single-option {
  background: #f2f2f2;
  padding: 10px;
  margin-bottom: 20px; }

.single-option-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.section-tab {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }
  .section-tab .content-wrapper {
    border: 1px solid #0072ea;
    border-top: none;
    padding: 30px 20px; }
  .section-tab .data-wrapper .data {
    display: flex;
    flex-wrap: wrap; }
  .section-tab .data-wrapper .info-header {
    padding-bottom: 10px;
    font-size: 1.2rem;
    font-weight: 700; }
  .section-tab .data-wrapper.input-data {
    padding-bottom: 30px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.details-tab {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }

.tab-details-content {
  border: 1px solid #0072ea;
  padding: 13.33333px 10px;
  width: 100%;
  font-size: 0.8rem; }
  .tab-details-content .tab-name.bold {
    font-size: 1.2rem;
    font-weight: 700; }
  .tab-details-content .tab-name span {
    display: flex;
    flex: 0 1 25%; }
  @media screen and (min-width: 768px) {
    .tab-details-content {
      align-items: center;
      display: flex;
      justify-content: space-between; } }

.icon-background {
  background-color: #0072ea;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 10px; }

.field-type {
  display: flex;
  align-items: center; }

.details-tab-wrapper {
  max-height: 0;
  transition: all 0.15s linear;
  padding: 0 10px;
  overflow: hidden;
  border: 0px solid #0072ea;
  color: rgba(0, 0, 0, 0);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.15); }
  .details-tab-wrapper.toggled {
    max-height: 600px;
    border-top: none;
    display: flex;
    padding: 10px;
    border: 1px solid #0072ea;
    border-top: none;
    color: #333;
    background: #f2f2f2; }

.client-wrapper,
.company-wrapper {
  display: flex;
  flex-direction: column;
  flex: 0 1 50%; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.nav-tab-wrapper {
  width: 100%;
  display: block;
  margin: 20px 0 40px; }
  @media screen and (min-width: 768px) {
    .nav-tab-wrapper {
      display: flex; } }
  .nav-tab-wrapper .nav-tab-button {
    font-size: 1.4rem;
    padding: 20px;
    display: flex;
    flex: 0 1 50%;
    justify-content: center; }
    .nav-tab-wrapper .nav-tab-button:hover {
      background-color: #ecf3fc;
      cursor: pointer; }
    .nav-tab-wrapper .nav-tab-button.active {
      background-color: #ecf3fc;
      color: #0072ea;
      font-weight: 700;
      border-bottom: 3px solid #0072ea; }
      .nav-tab-wrapper .nav-tab-button.active:hover {
        cursor: pointer; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.component-details {
  border: 1px solid #0072ea;
  padding: 20px;
  border-top: none;
  margin-bottom: 20px; }

.product-section-header {
  color: #0072ea; }
  @media screen and (max-width: 767px) {
    .product-section-header {
      margin-bottom: 20px; } }

@media screen and (min-width: 768px) {
  .component-subheader-container {
    display: flex;
    justify-content: space-between;
    align-items: center; } }

.line-tab-wrapper {
  border-left: 4px solid #0072ea;
  padding: 0;
  margin-bottom: 10px;
  justify-content: space-between;
  margin-left: -20px; }

.line-tab-inside {
  display: flex;
  align-items: center; }

.line-tab-content {
  display: flex;
  align-items: center; }

.line-tab-position {
  background: #004084;
  color: #fff;
  padding: 13.33333px;
  margin-right: 20px;
  font-weight: 700; }

.line-main-name {
  margin: 0 5px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.product-item {
  flex: 1 1; }
  @media screen and (min-width: 768px) {
    .product-item {
      display: flex; } }

.product-item-details {
  flex: 1 1; }

.product-item-children {
  margin: -10px;
  margin-top: 0;
  padding: 10px;
  flex: 1 1;
  background-color: #e6e6e6; }
  @media screen and (min-width: 768px) {
    .product-item-children {
      border-left: 1px solid #0072ea;
      margin-left: 20px;
      margin: -10px; } }

.product-item-parents {
  margin: -10px;
  margin-top: 0;
  padding: 10px;
  flex: 1 1; }
  @media screen and (min-width: 768px) {
    .product-item-parents {
      border-left: 1px solid #0072ea;
      margin-left: 20px;
      margin: -10px; } }

.product-item-header {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 10px; }

.product-subitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebebeb; }

.product-subitem-details {
  display: flex;
  font-size: 1rem; }

.product-subitem-name-code .product-subitem-name {
  font-weight: 600;
  display: block;
  margin-bottom: 0; }

.product-subitem-name-code .product-subitem-code {
  display: block;
  font-size: 0.75rem; }

.main-content {
  flex: 1 1;
  overflow: hidden; }

.error-page {
  background-image: url("/assets/bg-1.jpg");
  background-color: #fff;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }

* {
  box-sizing: border-box;
  outline: none; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

img {
  max-width: 100%; }

button {
  border: none;
  background: none; }

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

html {
  font-size: 15x; }
  @media screen and (min-width: 576px) {
    html {
      font-size: 15px; } }
  @media screen and (min-width: 768px) {
    html {
      font-size: 16px; } }

body {
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  line-height: normal;
  font-weight: 300; }

h2 {
  font-size: 1.5rem;
  font-weight: 700; }

h3 {
  font-size: 1.3rem;
  font-weight: 700; }

h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #333; }

strong {
  font-weight: 700; }

a {
  text-decoration: none;
  color: #333;
  transition: 0.3s color;
  cursor: pointer;
  font-weight: 600; }
  a:hover {
    color: #4d4d4d; }

p {
  color: #333;
  font-weight: 300; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.input,
input,
textarea {
  width: 100%;
  border: none;
  border: 1px solid #0072ea;
  box-shadow: none;
  background-color: #fff;
  font-size: 0.9rem;
  font-weight: 100;
  padding: 15.38462px 10px;
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  border-radius: 0;
  height: 50px; }
  .input::-webkit-input-placeholder, input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #dcdde7; }
  .input:-ms-input-placeholder, input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #dcdde7; }
  .input::placeholder,
  input::placeholder,
  textarea::placeholder {
    color: #dcdde7; }
  .input.light,
  input.light,
  textarea.light {
    background: #fff; }
  .input.dark,
  input.dark,
  textarea.dark {
    background: #fff; }
  .input.is-invalid,
  input.is-invalid,
  textarea.is-invalid {
    border-color: #ea282f; }
  .input:focus,
  input:focus,
  textarea:focus {
    box-shadow: 0 0 20px -10px #0072ea; }

.input-select > div:first-of-type {
  width: 100%;
  border: none;
  border: 1px solid #0072ea;
  box-shadow: none;
  background-color: #fff;
  font-size: 0.9rem;
  font-weight: 100;
  padding: 15.38462px 10px;
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  border-radius: 0; }

.input-select {
  min-width: 120px;
  white-space: nowrap; }
  .input-select input {
    height: auto; }
  .input-select > div:first-of-type {
    padding: 0 10px;
    height: 50px; }
    .input-select > div:first-of-type span {
      display: none; }
    .input-select > div:first-of-type > div:first-child {
      padding: 0;
      height: 100%; }
  .input-select.compact > div:first-of-type {
    height: 30px;
    font-size: 0.7rem;
    padding: 0 5px; }
  .input-select.compact .input-select__indicator {
    padding: 0; }
    .input-select.compact .input-select__indicator svg {
      width: 10px;
      height: 10px; }
  .input-select .input-select__menu {
    top: 48px;
    width: 100%; }

form .form-field .checkbox label {
  color: #333;
  font-size: 1rem; }

form .form-field .checkbox,
form .form-field .field-type-checkbox {
  display: block;
  margin: 5px; }
  form .form-field .checkbox .form-field-title,
  form .form-field .field-type-checkbox .form-field-title {
    margin-bottom: 0; }

form .form-field [type='checkbox']:checked,
form .form-field [type='checkbox']:not(:checked) {
  position: absolute;
  left: -9999px; }

form .form-field [type='checkbox']:checked + label,
form .form-field [type='checkbox']:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 10px;
  display: inline-block;
  font-weight: 500;
  padding-left: 2rem; }

form .form-field [type='checkbox']:checked + label:before,
form .form-field [type='checkbox']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  border: 1px solid #0072ea;
  padding: 3px;
  transition: 0.3s all;
  border-radius: 5px; }

form .form-field [type='checkbox']:checked + label:after,
form .form-field [type='checkbox']:not(:checked) + label:after {
  content: '';
  width: calc(1rem - 2px);
  height: calc(1rem - 2px);
  background: #0072ea;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  left: 5px;
  transition: background-color 0.2s ease, opacity 0.2s ease;
  border-radius: 3px; }

form .form-field [type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: translateY(-50%) scale(0);
  transform: scale(0); }

form .form-field [type='checkbox']:checked + label:after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

form .form-field [type='checkbox']:hover + label:before {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1);
  background: #e6e6e6;
  border-color: #0072ea; }

form label,
form .form-field-title {
  font-size: 0.9rem;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-family: "Source Sans Pro", sans-serif; }

form .form-field-errors {
  color: #ea282f;
  font-size: 0.8rem;
  margin-top: 5px; }

form textarea {
  min-height: 150px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  border-radius: 0;
  resize: vertical;
  padding: 10px; }

.form-submit-button {
  width: 100%;
  border-radius: 30px;
  border: 0;
  padding: 10px 20px;
  background: #0072ea;
  font-weight: 700;
  margin-top: 20px; }
  .form-submit-button.with-margin {
    margin-bottom: 60px; }
  .form-submit-button.with-little-margin {
    margin-bottom: 20px; }

.form-field textarea {
  border: 1px solid #0072ea;
  border-radius: 5px;
  width: 100%;
  resize: none; }

select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  background-image: url("/assets/arrow-down.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: #fff;
  border: transparent;
  outline: none;
  padding: 15px;
  color: #333;
  display: block;
  width: 100%;
  line-height: 1rem;
  transition: 0.3s background-color;
  font-size: 0.9rem;
  font-family: "Source Sans Pro", sans-serif;
  border-radius: 0; }

.row:before, .row:after {
  content: "";
  display: table;
  clear: both; }

.row {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap; }
  .row [class*="col-"] {
    flex: 0 1 100%;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 1px;
    display: flex;
    flex-direction: column; }
    .row [class*="col-"].vertical {
      flex-direction: row;
      justify-content: space-between; }
  @media screen and (min-width: 576px) {
    .row .col-xs-1 {
      flex: 0 1 8.33333%; }
    .row .col-xs-2 {
      flex: 0 1 16.66667%; }
    .row .col-xs-3 {
      flex: 0 1 25%; }
    .row .col-xs-4 {
      flex: 0 1 33.33333%; }
    .row .col-xs-5 {
      flex: 0 1 41.66667%; }
    .row .col-xs-6 {
      flex: 0 1 50%; }
    .row .col-xs-7 {
      flex: 0 1 58.33333%; }
    .row .col-xs-8 {
      flex: 0 1 66.66667%; }
    .row .col-xs-9 {
      flex: 0 1 75%; }
    .row .col-xs-10 {
      flex: 0 1 83.33333%; }
    .row .col-xs-11 {
      flex: 0 1 91.66667%; }
    .row .col-xs-12 {
      flex: 0 1 100%; } }
  @media screen and (min-width: 768px) {
    .row .col-sm-1 {
      flex: 0 1 8.33333%; }
    .row .col-sm-2 {
      flex: 0 1 16.66667%; }
    .row .col-sm-3 {
      flex: 0 1 25%; }
    .row .col-sm-4 {
      flex: 0 1 33.33333%; }
    .row .col-sm-5 {
      flex: 0 1 41.66667%; }
    .row .col-sm-6 {
      flex: 0 1 50%; }
    .row .col-sm-7 {
      flex: 0 1 58.33333%; }
    .row .col-sm-8 {
      flex: 0 1 66.66667%; }
    .row .col-sm-9 {
      flex: 0 1 75%; }
    .row .col-sm-10 {
      flex: 0 1 83.33333%; }
    .row .col-sm-11 {
      flex: 0 1 91.66667%; }
    .row .col-sm-12 {
      flex: 0 1 100%; } }
  @media screen and (min-width: 920px) {
    .row .col-md-1 {
      flex: 0 1 8.33333%; }
    .row .col-md-2 {
      flex: 0 1 16.66667%; }
    .row .col-md-3 {
      flex: 0 1 25%; }
    .row .col-md-4 {
      flex: 0 1 33.33333%; }
    .row .col-md-5 {
      flex: 0 1 41.66667%; }
    .row .col-md-6 {
      flex: 0 1 50%; }
    .row .col-md-7 {
      flex: 0 1 58.33333%; }
    .row .col-md-8 {
      flex: 0 1 66.66667%; }
    .row .col-md-9 {
      flex: 0 1 75%; }
    .row .col-md-10 {
      flex: 0 1 83.33333%; }
    .row .col-md-11 {
      flex: 0 1 91.66667%; }
    .row .col-md-12 {
      flex: 0 1 100%; } }
  @media screen and (min-width: 1200px) {
    .row .col-lg-1 {
      flex: 0 1 8.33333%; }
    .row .col-lg-2 {
      flex: 0 1 16.66667%; }
    .row .col-lg-3 {
      flex: 0 1 25%; }
    .row .col-lg-4 {
      flex: 0 1 33.33333%; }
    .row .col-lg-5 {
      flex: 0 1 41.66667%; }
    .row .col-lg-6 {
      flex: 0 1 50%; }
    .row .col-lg-7 {
      flex: 0 1 58.33333%; }
    .row .col-lg-8 {
      flex: 0 1 66.66667%; }
    .row .col-lg-9 {
      flex: 0 1 75%; }
    .row .col-lg-10 {
      flex: 0 1 83.33333%; }
    .row .col-lg-11 {
      flex: 0 1 91.66667%; }
    .row .col-lg-12 {
      flex: 0 1 100%; } }

* {
  margin: 0; }

html,
body {
  height: 100%; }

body {
  background-color: #fff; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.offer-footer {
  align-items: center;
  flex-direction: column;
  align-items: flex-start; }
  .offer-footer .footer-company {
    font-weight: 600;
    padding-bottom: 10px; }
  @media screen and (min-width: 768px) {
    .offer-footer {
      flex-direction: row;
      padding-top: 20px;
      align-items: center; } }

.offer-footer > div, .offer-footer > p {
  padding-top: 10px; }

.offer-footer:first-child {
  padding-top: 0; }

.status-shared {
  margin-bottom: 20px; }

.status-shared-info {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.offer-link {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #0072ea; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.offer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }
  .offer-header .offer-title {
    font-size: 1.6em;
    text-transform: uppercase;
    margin-right: 40px;
    font-weight: 700;
    padding: 10px 0; }
    @media screen and (min-width: 768px) {
      .offer-header .offer-title {
        white-space: nowrap; } }
  .offer-header .offer-number {
    font-weight: 400; }
  .offer-header .offer-buttons {
    flex: 1 1;
    white-space: nowrap;
    flex-wrap: nowrap; }

.offer-subheader {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 40px; }
  .offer-subheader .offer-valid {
    display: flex;
    font-size: 1.2em;
    flex-direction: column; }
    .offer-subheader .offer-valid .offer-date {
      font-weight: 600; }
  .offer-subheader .offer-days {
    font-style: italic;
    display: flex;
    justify-content: flex-end;
    font-size: 1.3em;
    padding-top: 10px; }
    .offer-subheader .offer-days .remaning-days {
      font-weight: 800;
      padding-right: 8px; }
  @media screen and (min-width: 768px) {
    .offer-subheader {
      flex-direction: row;
      align-items: center; }
      .offer-subheader .offer-valid {
        flex-direction: row; }
        .offer-subheader .offer-valid .offer-date {
          padding-left: 5px; }
      .offer-subheader .offer-days {
        padding-top: 0; } }

.offer-header-top {
  margin-bottom: 20px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.offer-section {
  width: 100%;
  background-color: #ecf3fc;
  display: flex;
  justify-content: center;
  font-size: 13px; }
  @media screen and (min-width: 768px) {
    .offer-section {
      font-size: 15px; } }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.offer-accept {
  align-items: center;
  padding-bottom: 30px; }
  .offer-accept .accept-info {
    color: #8cba51;
    font-style: italic;
    padding: 20px 0 30px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.offer-info {
  margin-bottom: 40px; }
  @media screen and (min-width: 768px) {
    .offer-info {
      display: flex; }
      .offer-info .client-data {
        flex: 0 0 50%;
        margin-right: 5px; }
      .offer-info .contact-person-data {
        flex: 0 0 50%;
        margin-left: 5px; } }

.company-name {
  font-size: 1.5em;
  font-weight: 700;
  padding-bottom: 10px; }

.company-address {
  font-size: 1.2em;
  font-weight: 400;
  padding-bottom: 20px; }

.company-details {
  font-size: 1.2em;
  font-weight: 400; }
  .company-details.with-padding {
    padding-bottom: 10px; }
  .company-details .bold {
    font-weight: 600; }

.offer-receiver {
  font-size: 1.4rem;
  margin-bottom: 10px; }

.offer-subtitle {
  border-bottom: 1px solid #0072ea;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 1.1rem; }
  .offer-subtitle.no-border {
    border-bottom: 0;
    padding-bottom: 0; }
  .offer-subtitle.no-margin-bottom {
    margin-bottom: 0; }

.single-change {
  display: flex;
  font-size: 0.9rem;
  align-items: center;
  justify-content: space-between; }
  .single-change span:first-of-type {
    margin: 0 3.33333px; }
  .single-change .quantity-separator {
    margin: 0 3.33333px; }
  .single-change .single-change-content {
    display: flex;
    align-items: center; }

.component-changes-header {
  font-size: 1.1rem;
  margin: 10px 0; }

.component-changes-container {
  padding-left: 1.2rem; }

.offer-changes-header {
  font-weight: 300;
  font-size: 1rem;
  margin: 10px 0; }

.offer-client-description {
  margin: 10px 0;
  padding-left: 20px;
  margin-bottom: 40px;
  list-style-type: disc;
  display: block; }
  .offer-client-description .offer-client-description-image {
    max-width: 350px;
    margin: 10px 0; }
    .offer-client-description .offer-client-description-image img {
      width: 100%; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.offer-subject {
  margin-bottom: 40px; }

.offer-subject-quantity {
  font-weight: 700;
  color: #0072ea; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.offer-payment-method {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

.offer-payment-icon {
  margin-right: 3.33333px;
  color: #0072ea; }

.offer-summary-section {
  margin-bottom: 30px; }

.offer-reason {
  margin-bottom: 40px; }

/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: 10px; }

/*tax-rate-table*/
.tax-rate-table-header {
  padding: 10px; }

/*tax-rate-header-item*/
.tax-rate-header {
  margin-top: 20px; }

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: 10px;
  font-weight: 600; }

.offer-container {
  background: #f2f2f2;
  height: 100%; }

.offer-wrapper {
  background: #fff;
  padding: 10px; }
  @media screen and (min-width: 768px) {
    .offer-wrapper {
      padding: 30px; } }

