@import '../../../config/variables.scss';

.component-changes-header {
  font-size: 1.1rem;
  margin: $main-spacing/2 0;
}

.component-changes-container {
  padding-left: 1.2rem;
}

.offer-changes-header {
  font-weight: 300;
  font-size: 1rem;
  margin: $main-spacing/2 0;
}
