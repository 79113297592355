@import '../config/mixins.scss';
@import '../config/variables.scss';

.input,
input,
textarea {
  width: 100%;
  border: none;
  border: 1px solid $color-primary;
  box-shadow: none;
  background-color: $color-background;
  font-size: 0.9rem;
  font-weight: 100;
  padding: $main-spacing / 1.3 $main-spacing / 2;
  color: $color-text;
  font-family: $main-font;
  font-weight: 500;
  border-radius: 0;
  height: 50px;

  &::placeholder {
    color: #dcdde7;
  }

  &.light {
    background: $color-background;
  }
  &.dark {
    background: $color-background;
  }

  &.is-invalid {
    border-color: $color-danger;
  }

  &:focus {
    box-shadow: 0 0 $main-spacing ($main-spacing/-2) $color-primary;
  }
}

.input-select {
  > div:first-of-type {
    width: 100%;
    border: none;
    border: 1px solid $color-primary;
    box-shadow: none;
    background-color: $color-background;
    font-size: 0.9rem;
    font-weight: 100;
    padding: $main-spacing / 1.3 $main-spacing / 2;
    color: $color-text;
    font-family: $main-font;
    font-weight: 500;
    border-radius: 0;
  }
}
.input-select {
  min-width: 120px;
  white-space: nowrap;
  input {
    height: auto;
  }
  & > div:first-of-type {
    padding: 0 $main-spacing/2;
    height: 50px;
    span {
      display: none;
    }
    > div:first-child {
      padding: 0;
      height: 100%;
    }
  }

  &.compact {
    & > div:first-of-type {
      height: 30px;
      font-size: 0.7rem;
      padding: 0 5px;
    }

    .input-select__indicator {
      padding: 0;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  .input-select__menu {
    top: 48px;
    width: 100%;
  }
}

form {
  .form-field {
    .checkbox {
      label {
        color: $color-text;
        font-size: 1rem;
      }
    }
    .checkbox,
    .field-type-checkbox {
      display: block;
      margin: $main-spacing / 4;
      .form-field-title {
        margin-bottom: 0;
      }
    }
    [type='checkbox']:checked,
    [type='checkbox']:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type='checkbox']:checked + label,
    [type='checkbox']:not(:checked) + label {
      position: relative;
      cursor: pointer;
      line-height: 10px;
      display: inline-block;
      font-weight: 500;
      padding-left: 2rem;
    }
    [type='checkbox']:checked + label:before,
    [type='checkbox']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      border: 1px solid $color-primary;
      padding: 3px;
      transition: $transition-time all;
      border-radius: 5px;
    }
    [type='checkbox']:checked + label:after,
    [type='checkbox']:not(:checked) + label:after {
      content: '';
      width: calc(1rem - 2px);
      height: calc(1rem - 2px);
      background: $color-primary;
      position: absolute;
      top: 50%;
      transform: translate(-50%);
      left: 5px;
      transition: background-color 0.2s ease, opacity 0.2s ease;
      border-radius: 3px;
    }
    [type='checkbox']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: translateY(-50%) scale(0);
      transform: scale(0);
    }
    [type='checkbox']:checked + label:after {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }

    [type='checkbox']:hover + label:before {
      opacity: 1;
      transform: translateY(-50%) scale(1);
      background: darken(#fff, 10%);
      border-color: $color-primary;
    }
  }
  label,
  .form-field-title {
    font-size: 0.9rem;
    font-weight: 400;
    display: block;
    margin-bottom: $main-spacing / 4;
    color: $color-text;
    font-family: $main-font;
  }

  .form-field-errors {
    color: $color-danger;
    font-size: 0.8rem;
    margin-top: $main-spacing / 4;
  }
  textarea {
    min-height: 150px;
    font-family: $main-font;
    color: $color-text;
    border-radius: 0;
    resize: vertical;
    padding: $main-spacing/2;
  }
}

.form-submit-button {
  width: 100%;
  border-radius: 30px;
  border: 0;
  padding: $main-spacing * 0.5 $main-spacing;
  background: $color-primary;
  font-weight: 700;
  margin-top: $main-spacing;

  &.with-margin {
    margin-bottom: $main-spacing * 3;
  }
  &.with-little-margin {
    margin-bottom: $main-spacing;
  }
}

.form-field {
  textarea {
    border: 1px solid $color-primary;
    border-radius: 5px;
    width: 100%;
    resize: none;
  }
}

select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  background-image: url('/assets/arrow-down.svg');
  background-position: right $main-spacing / 2 center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: $color-background;
  border: transparent;
  outline: none;
  padding: $main-spacing * 0.75;
  color: $color-text;
  display: block;
  width: 100%;
  line-height: 1rem;
  transition: $transition-time background-color;
  font-size: 0.9rem;
  font-family: $main-font;
  border-radius: 0;
}
