@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.header-wrapper {
  align-items: center;
  display: block;
  @include lt-sm {
    padding-bottom: $main-spacing;
  }

  @include gt-sm {
    display: flex;
    justify-content: space-between;
  }

  .header-main {
    display: flex;
    align-items: center;
    margin-bottom: $main-spacing;

    @include gt-sm {
      margin-bottom: 0;
    }
  }

  .section-header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;

    @include lt-md {
      padding-left: $main-spacing;
    }

    @include gt-sm {
      font-size: 1.5rem;
    }

    @include gt-md {
      font-size: 1.8rem;
    }
  }
}

.header-additional {
  display: flex;
}
