@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.input-container,
.form-field {
  min-width: 80px;
  position: relative;
  margin-bottom: $main-spacing * 0.5;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  ::placeholder {
    color: #dcdde7;
  }
  &.nomargin {
    margin-bottom: 0;

    .input-wrapper {
      margin-bottom: 0;
    }
  }

  &.hidden {
    display: none;
    border: 1px solid pink;
  }
  label {
    font-size: 0.9rem;
    font-weight: 400;
    display: block;
    margin-bottom: $main-spacing / 4;
    color: $color-text;
    font-family: $main-font;
  }

  input[readonly] {
    background: darken($color-background, 10%);
  }

  &.compact {
    label {
      font-size: 0.75rem;
      margin-bottom: 0;
    }
    input {
      font-size: 0.7rem;
      padding: $main-spacing/2;
      height: 40px;
    }
  }

  &.rounded {
    input {
      border-radius: $border-radius;
    }
  }

  &.input-with-icon {
    input {
      padding-right: 1.5rem;
    }
  }
}

.dark-background {
  background: $color-background;
  border: none;
}

.input-wrapper {
  position: relative;
  margin-bottom: $main-spacing / 4;
}

.input-group {
  display: flex;
  align-items: center;
}

.input-icon {
  font-weight: 400;
  font-size: 0.9rem;
  z-index: 1;
  position: absolute;
  top: 50%;
  right: $main-spacing/2;
  transform: translate(0, -50%);
  color: $color-text;
}

.text-danger {
  color: $color-danger;
  font-size: 0.8rem;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: $main-spacing * 3;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: $main-spacing/2;
  margin-bottom: $main-spacing/2;
  color: $color-text-light;
  font-weight: 300;

  &.dark-label {
    color: $color-text;
  }
}

.chekbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: $checkbox-side;
  width: $checkbox-side;
  background-color: #eee;
  border: 1px solid $color-primary;
}

.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: $color-primary;
}

.checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-label-label {
  margin-left: -$main-spacing * 1.5;
}

.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

.checkbox-container .checkbox-checkmark:after {
  left: 5px;
  top: 3px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

[type='checkbox'] {
  display: none;
}

.radio-container {
  display: block;
  position: relative;
  padding-left: $main-spacing * 3;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: $main-spacing/2;
  margin-bottom: $main-spacing/2;
  color: $color-text-light;
  font-weight: 300;

  &.dark-label {
    color: $color-text;
  }
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: $checkbox-side;
  width: $checkbox-side;
  background-color: #eee;
  border: 1px solid $color-primary;
  border-radius: 50%;
}

.radio-container input:checked ~ .radio-checkmark {
  background-color: $color-primary;
}

.radio-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-label-label {
  margin-left: -$main-spacing * 1.5;
}

.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}

.radio-container .radio-checkmark:after {
  left: 5px;
  top: 3px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

[type='radio'] {
  display: none;
}

/*.select-label {
  width: 100%;
  display: block;
  font-size: 0.9rem;
  select {
    width: 100%;
    height: $main-spacing * 4;
    border-radius: 7px;
    font-family: $main-font;
    font-size: 1.1rem;
    border: 1px solid $color-text-alter;
    background: $color-background;
    appearance: none;
    padding: $main-spacing;
  }
}*/

.select-label-text {
  color: $color-text;
  padding-bottom: $main-spacing/2;
  padding-top: $main-spacing/2;
  display: block;
}

.text-input-container {
  width: 100%;
  input {
    width: 100%;
    height: $main-spacing * 4;
    border-radius: 7px;
    font-family: $main-font;
    font-size: 1.1rem;
    border: 1px solid $color-text;
    appearance: none;
    padding: $main-spacing;
  }
}

.checkbox-outer-cont {
  margin-bottom: $main-spacing/2;
}

.toolbar-input {
  width: 100%;
  border: none;
  background-color: darken(#fff, 8%);
  font-size: 0.9rem;
  font-weight: 100;
  padding: $main-spacing/2;
  color: #333;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 0;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.05);
  height: 2 * $main-spacing;
  ::placeholder {
    color: #333;
  }
}
