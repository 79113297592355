@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.card {
  width: 100%;
  background-color: $color-background;

  @include lt-md {
    margin: 0;
  }
}
