@import '../../../config/variables.scss';

.offer-subtitle {
  border-bottom: 1px solid $color-primary;
  margin-bottom: $main-spacing/2;
  padding-bottom: $main-spacing/2;
  font-weight: 600;
  font-size: 1.1rem;

  &.no-border {
    border-bottom: 0;
    padding-bottom: 0;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }
}
