.error-page {
  background-image: url("/assets/bg-1.jpg");
  background-color: #fff;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
