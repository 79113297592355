@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap');
@import './variables.scss';
@import './mixins.scss';

html {
  font-size: 15x;

  @include gt-xs {
    font-size: 15px;
  }
  @include gt-sm {
    font-size: 16px;
  }
}

body {
  color: $color-text;
  font-family: $main-font;
  line-height: normal;
  font-weight: 300;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
}
h3 {
  font-size: 1.3rem;
  font-weight: 700;
}

h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: $color-text;
}

strong {
  font-weight: 700;
}

a {
  text-decoration: none;
  color: $color-text;
  transition: $transition-time color;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: lighten($color-text, 10%);
  }
}

p {
  color: $color-text;
  font-weight: 300;
}
