@import '../../../config/variables.scss';

.graph-label {
  font-size: 0.9rem;
  display: block;
  margin-bottom: $main-spacing/4;
}

.graph-area {
  border: 1px solid $color-text;
  min-height: 100px;
  padding: $main-spacing/4;
  padding-top: $main-spacing/1.5;
  margin-bottom: $main-spacing/2;
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  align-items: center;
  align-content: flex-start;
  cursor: pointer;
  overflow: auto;

  &.active-dropzone {
    box-shadow: $soft-shadow-bottom;
    border: 1px solid $color-primary;
  }
}

.graph-editor {
  margin-bottom: $main-spacing;
  background: $color-background;

  &.full-screen {
    padding: $main-spacing;

    .graph-area {
      min-height: 500px;
    }
  }
}

.graph-header {
  display: flex;
  justify-content: space-between;
}
.graph-buttons {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .button {
    margin: $main-spacing/6;
  }
}
