@import "../../../config/variables.scss";

.bottom-links {
  font-size: 0.9rem;
  padding-bottom: $main-spacing *3;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  a {
    color: $color-primary;
    font-weight: 400;
    display: block;

    &:hover {
      color: lighten($color-primary, 20%);
      cursor: pointer;
    }
  }
}
