@import '../../../config/mixins.scss';
@import '../../../config/variables.scss';

.detail-line {
  margin-bottom: $main-spacing/4;
  padding-bottom: $main-spacing/4;
  border-bottom: 1px solid darken($color-background, 8%);
  font-size: 0.9rem;

  &:last-child {
    margin-bottom: 0;
    // padding-bottom: 0;
    border-bottom: none;
  }
  .details-title {
    padding-right: $main-spacing/2;
  }

  .details-data {
    font-weight: 600;
    display: block;
  }

  &.break-line {
    span {
      display: block;
    }
  }

  &.important {
    .details-title {
      font-weight: 500;
    }

    .details-data {
      font-weight: 600;
      color: $color-primary;
    }
  }
}
