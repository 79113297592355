@import '../../../config/variables.scss';

.ratio-container {
    display: flex;
    font-size: 0.9rem;
    background-color: $color-primary;
    padding: $main-spacing/2;
    color: $color-background;

    &.inside {
        padding: 0;
    }

    .ratio-value {
        font-weight: 700;
        margin-left: $main-spacing/4;
    }
}