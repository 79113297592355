@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.container {
  margin: 0px auto;
  padding: $main-spacing/2;
  padding-bottom: $main-spacing * 2;
  width: 100%;
  max-width: $max-page-width;

  &.narrow {
    max-width: $max-page-width * 0.8;
  }

  &.fluid {
    max-width: 100%;
  }

  @include gt-sm {
    padding: $main-spacing;
    padding-bottom: $main-spacing * 4;
  }
}
