@import '../../../config/mixins.scss';
@import '../../../config/variables.scss';

.offer-subject {
  margin-bottom: $main-spacing * 2;
}

.offer-subject-quantity {
  font-weight: 700;
  color: $color-primary;
}
