@import '../../../config/variables.scss';

.offer-client-description {
  margin: $main-spacing/2 0;
  padding-left: $main-spacing;
  margin-bottom: $main-spacing * 2;
  list-style-type: disc;
  display: block;
  .offer-client-description-image {
    max-width: 350px;
    margin: $main-spacing/2 0;

    img {
      width: 100%;
    }
  }
}
