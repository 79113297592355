@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.section-header-wrapper {
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: $main-spacing;
  margin-top: $main-spacing;

  &.no-margin-top {
    margin-top: 0;
  }

  @include gt-sm {
    flex-direction: row;
  }

  .section-title {
    font-weight: 600;
    font-size: 1.3rem;

    @include gt-md {
      padding-left: 0;
    }

    @include lt-sm {
      width: 100%;
      display: flex;
      margin-bottom: $main-spacing/2;
    }
  }
}

.subtitle-header {
  font-size: 1.1rem;
  color: $color-primary;
  font-style: italic;
  font-weight: 500;
  padding: 0 0 $main-spacing * 1.5 0;
}
