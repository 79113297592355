@import '../../../../config/variables.scss';
@import '../../../../config/mixins.scss';

.details-tab {
  display: flex;
  flex-direction: column;
  margin-bottom: $main-spacing;
}

.tab-details-content {
  border: 1px solid $color-primary;
  padding: $main-spacing/1.5 $main-spacing/2;
  width: 100%;
  font-size: 0.8rem;

  .tab-name {
    &.bold {
      font-size: 1.2rem;
      font-weight: 700;
    }
    span {
      display: flex;
      flex: 0 1 25%;
    }
  }

  @include gt-sm {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
.icon-background {
  background-color: $color-primary;
  color: $color-background;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.field-type {
  display: flex;
  align-items: center;
}

.details-tab-wrapper {
  max-height: 0;
  transition: all $transition-time/2 linear;
  padding: 0 $main-spacing/2;
  overflow: hidden;
  border: 0px solid $color-primary;
  color: rgba(0, 0, 0, 0);
  box-shadow: $soft-shadow-bottom;

  &.toggled {
    max-height: 600px;
    border-top: none;
    display: flex;
    padding: $main-spacing/2;
    border: 1px solid $color-primary;
    border-top: none;
    color: $color-text;
    background: darken($color-background, 5%);
  }
}

.client-wrapper,
.company-wrapper {
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
}
