@import '../../../../config/variables.scss';
@import '../../../../config/mixins.scss';

.component-details {
  border: 1px solid $color-primary;
  padding: $main-spacing;
  border-top: none;
  margin-bottom: $main-spacing;
}

.product-section-header {
  color: $color-primary;
  @include lt-sm {
    margin-bottom: $main-spacing;
  }
}

.component-subheader-container {
  @include gt-sm {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
