@import '../../../../config/variables.scss';

.line-tab-wrapper {
  border-left: 4px solid $color-primary;
  padding: 0;
  margin-bottom: $main-spacing/2;
  justify-content: space-between;
  margin-left: -1 * $main-spacing;
}

.line-tab-inside {
  display: flex;
  align-items: center;
}

.line-tab-content {
  display: flex;
  align-items: center;
}

.line-tab-position {
  background: darken($color-primary, 20%);
  color: $color-text-light;
  padding: $main-spacing/1.5;
  margin-right: $main-spacing;
  font-weight: 700;
}

.line-main-name {
  margin: 0 $main-spacing/4;
}
