@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

%button {
  display: flex;
  letter-spacing: 1px;
  background-color: darken($color-background, 1%);
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-family: $main-font;
  font-size: 1rem;
  line-height: 1.2rem;
  border: none;
  box-shadow: $soft-shadow-bottom;
  transition: $transition-time;
  outline: none;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  min-height: 90%;
  min-height: 50px;
  text-transform: uppercase;

  @include gt-sm {
    min-width: 300px;
  }

  @include show($transition-time);

  &:link,
  &:active,
  &:visited {
    color: $color-background;
  }

  &:hover {
    background: darken($color-background, 5%);
  }
}

.button-text {
  text-align: center;
  z-index: 0;

  &.button-icon-only {
    @include lt-sm {
      display: none;
    }
  }
}

.button {
  @extend %button;
  color: $color-text-light;
  &:link,
  &:active,
  &:visited {
    color: $color-text-light;
  }

  &:hover {
    background: darken($color-text-light, 5%);
    // color: $color-background;
  }

  &.button-reversed {
    min-width: auto;
    min-height: auto;
    background-color: transparent;
    color: $color-text;
    text-transform: uppercase;
    font-size: 0.9rem;
    padding: $main-spacing/2;
    box-shadow: none;
    &:hover {
      background-color: darken($color-background, 10%);
    }
  }
}

.button-primary {
  @extend %button;
  background-color: $color-primary;
  color: $color-text-light;
  text-transform: uppercase;
  &:link,
  &:active,
  &:visited {
    color: $color-background;
  }
  &:hover {
    color: $color-background;
    background: lighten($color-primary, 5%);
  }
  &.button-reversed {
    color: $color-primary;
    &:hover {
      background-color: $color-primary-light;
    }
  }
}

.button-secondary {
  @extend %button;
  background-color: $color-secondary;
  color: $color-background;
  text-transform: uppercase;
  font-size: 1rem;
  &:link,
  &:active,
  &:visited {
    color: $color-background;
  }
  &:hover {
    background: lighten($color-secondary, 5%);
  }
  @include lt-sm {
    width: 100%;
  }
}

.button-outline {
  @extend %button;
  background-color: transparent;
  color: $color-primary;
  border: 2px solid $color-primary;
  font-size: 1rem;
  text-transform: uppercase;
  &:link,
  &:active,
  &:visited {
    color: $color-primary;
  }
  &:hover {
    background: rgba($color-primary, 0.3);
    color: $color-primary;
  }
}

.button-danger {
  @extend %button;
  background: $color-danger;
  color: $color-text-light;
  &:link,
  &:active,
  &:visited {
    color: $color-text-light;
  }
  &:hover {
    background: lighten($color-danger, 5%);
    color: $color-text-light;
  }

  &.button-reversed {
    color: $color-danger;
    &:hover {
      background-color: lighten($color-danger, 40%);
    }
  }
}

.button-success {
  @extend %button;
  background: $color-success;
  color: $color-background;
  &:link,
  &:active,
  &:visited {
    color: $color-background;
  }
  &:hover {
    background: lighten($color-success, 5%);
    color: $color-background;
  }
}

.button-small {
  min-width: 200px;
  font-size: 1rem;
  min-height: 45px;
  padding: $main-spacing/2;
}

.button-extra-small {
  font-size: 0.8rem;
  min-width: unset;
  min-height: unset;
}

.button-big {
  padding: $main-spacing $main-spacing * 5;
  font-size: 1.05rem;
}

.button-disabled {
  background: $color-disabled;
  opacity: 0.5;
  color: $color-text-light;
  cursor: not-allowed;

  &:hover {
    background: $color-disabled;
    color: $color-text-light;
  }
}

.button-icon {
  display: flex;
  align-items: center;
}

.button-left-icon {
  .button-icon {
    height: 1.5rem;
    margin: 0 $main-spacing/2 0 0;
  }
  &.button-no-text {
    .button-icon {
      margin: 0;
    }
  }
}
.button-right-icon {
  .button-icon {
    height: 1.5rem;
    margin: 0 0 0 $main-spacing/2;
  }

  &.button-no-text {
    .button-icon {
      margin: 0;
    }
  }
}

.button-link {
  min-width: auto;
  box-shadow: none;
  color: $color-primary;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;

  &:link,
  &:visited {
    color: $color-primary;
  }
  &:hover {
    color: $color-primary;
    background-color: transparent;
  }
  &:active {
    color: $color-primary;
  }
}
