@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.profile-circle-wrapper {
  position: relative;
  display: flex;
  flex: 0 1 50%;
  background-color: $color-primary;
  overflow: hidden;
  .circle {
    position: absolute;
    width: 950px;
    height: 950px;
    border-radius: 50%;
    background-color: $color-background;
    &.first {
      top: 5%;
      opacity: 0.1;
      left: -50%;
    }
    &.second {
      bottom: -40%;
      opacity: 0.15;
      left: -5%;
    }

    @include lt-md {
      width: 450px;
      height: 450px;

      &.first {
        top: -15%;
      }
    }
  }

  .profile-circle {
    min-height: 15vh;
    display: flex;
    align-items: flex-end;

    @include gt-md {
      min-height: 10%;
    }
  }

  .mobile-hello-text {
    @include gt-sm {
      display: none;
    }
  }
}
