@import '../../../config/variables.scss';

.spinner-container {
  min-height: 300px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: $color-background;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &.overlay {
    position: absolute;
    min-height: auto;
  }

  &.half-transparent {
    background: rgba($color-background, 0.5);
  }

  &.transparent {
    background: transparent;
  }

  &.dark {
    background: $color-background;
  }

  .spinner {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 4px solid $color-primary;
    border-radius: 50%;
    animation: spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color-primary transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &.small {
    .spinner {
      width: 20px;
      height: 20px;
    }
  }
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
