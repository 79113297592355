@import '../../../config/variables';
@import '../../../config/mixins.scss';

.sidebar {
  background-color: $color-background;
  flex: 0 0 80%;
  position: fixed;
  overflow: hidden;
  width: 80%;
  box-shadow: $soft-shadow;
  margin-left: -110%;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  transition: $transition-time margin-left;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  overflow: auto;

  .sidebar-logo {
    display: none;
  }

  &.toggled {
    margin-left: 0;
  }

  @include gt-md {
    position: relative;
    height: 100%;
    width: $sidebar-width * 0.95;
    flex: 0 0 $sidebar-width * 0.95;
    font-size: 1rem;
    margin-left: 0;

    .sidebar-logo {
      display: block;
      padding: 0 $main-spacing;
    }
  }
}

.sidebar-toggle {
  position: absolute;
  top: $main-spacing/2;
  left: $main-spacing/2;
}

.sidebar-container {
  overflow: auto;
  position: relative;
  // width: $sidebar-width;

  @include gt-md {
    position: fixed;
    width: $sidebar-width * 0.95;
  }
}

.sidebar-shadow {
  transition: $transition-time;
  &.toggled {
    position: fixed;
    background: rgba($color-text, 0.5);
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    right: 0;
  }

  @include gt-md {
    display: none;
  }
}

.sidebar-button {
  padding: $main-spacing;
  display: flex;

  @include gt-md {
    display: none;
  }

  .close-button {
    color: $color-text;
    box-shadow: none;
    justify-content: flex-end;

    &:hover {
      background-color: $color-background;
      cursor: pointer;
      color: $color-primary;
    }
  }
}
