@import "../../../config/variables";
@import "../../../config/mixins";

.notification {
  animation-name: show;
  animation-duration: 0.3s;
  cursor: pointer;
  opacity: 0.99;

  z-index: 999;

  .alert {
    margin: 0;
    border-radius: 0;
    padding: $main-spacing;
  }

  @include gt-sm {
    padding: $main-spacing/4 $main-spacing;
    .alert {
      padding: $main-spacing / 1.5;
      margin: 0;
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
