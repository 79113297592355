@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.info-paragraph {
  padding: $main-spacing*1.5 0;

  &.no-margin {
    padding: 0;
  }

  &.tab {
    color: $color-primary;
    font-size: 1.1rem;
    font-weight: 600;
  }
}