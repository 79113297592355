@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.form-container {
  flex: 1;
  position: relative;
  max-width: $form-width;
  margin: 0px auto;
  width: 100%;

  &.full {
    max-width: unset;
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .form-buttons {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  @include gt-sm {
    margin: 0;

    form {
      display: block;
    }
  }
}
