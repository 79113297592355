@import '../../../../config/mixins.scss';
@import '../../../../config/variables.scss';

.section-tab {
  display: flex;
  flex-direction: column;
  margin-bottom: $main-spacing;

  .content-wrapper {
    border: 1px solid $color-primary;
    border-top: none;
    padding: $main-spacing * 1.5 $main-spacing;
  }

  .data-wrapper {
    .data {
      display: flex;
      flex-wrap: wrap;
    }

    .info-header {
      padding-bottom: $main-spacing/2;
      font-size: 1.2rem;
      font-weight: 700;
    }

    &.input-data {
      padding-bottom: $main-spacing * 1.5;
    }
  }
}
