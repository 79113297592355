@import '../../../config/variables.scss';

.api-list {
  max-height: 70vh;
  overflow: auto;
}
.api-list-header {
  font-size: 0.8rem;
  display: flex;
  margin-bottom: $main-spacing;
  font-weight: 700;
}
