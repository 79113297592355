@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.offer-payment-method {
  display: flex;
  align-items: center;
  margin-bottom: $main-spacing/2;
}

.offer-payment-icon {
  margin-right: $main-spacing/6;
  color: $color-primary;
}

.offer-summary-section {
  margin-bottom: $main-spacing * 1.5;
}
