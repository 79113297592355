@import "../../../../config/variables.scss";

@mixin ripple-bg($color) {
  @if (lightness($color) < 50%) {
    background: radial-gradient(
      10px at center,
      lighten($color, 20%) 0%,
      lighten($color, 20%) 99%,
      rgba($color, 0%) 100%
    );
  } @else {
    background: radial-gradient(10px at center, darken($color, 20%) 0%, darken($color, 20%) 99%, rgba($color, 0%) 100%);
  }
}

.ripple {
  position: absolute;
  width: 300%;
  height: 300%;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  animation: ripple 1s 1;
  animation-fill-mode: none;
  z-index: 0;
  @include ripple-bg($color-background);

  &.primary {
    @include ripple-bg($color-primary);
  }

  &.secondary {
    @include ripple-bg($color-secondary);
  }

  &.success {
    @include ripple-bg($color-success);
  }
  &.danger {
    @include ripple-bg($color-danger);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(20);
  }
}
