@import '../../../config/variables.scss';
@import "../../../config/mixins.scss";

.login-wrapper {
  @include lt-md {
    flex-direction: column-reverse;
  }

  .login-section {
    flex: 0 1 50%;
    padding: $main-spacing;
    align-items: center;

    .content-wrapper {
      max-width: 500px;
    }
  }
}
