@import '../../../config/variables';

.alert:not(.alert-simple) {
  font-family: $main-font;
  margin: 20px 0;
  outline: none;
  font-weight: 500;
  padding: $main-spacing / 1.5;
  text-decoration: none;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;

  &.alert-error {
    background: rgba($color-danger, 1);
  }

  &.alert-notice {
    background: rgba($color-notice, 1);
  }

  // &.alert-warning {
  //   background: rgba($color-warning, 1);
  // }

  &.alert-success {
    background: rgba($color-success, 1);
  }

  // &.alert-disabled {
  //   background: rgba($color-disabled, 1);
  // }
  &.list {
    background: $color-background;
    border: 1px solid $color-danger;
    color: $color-danger;
  }
}

.alert-icon {
  margin-right: $main-spacing/2;
  font-size: 1.2rem;
}

.alert {
  &.alert-simple {
    display: flex;
    align-items: center;
    &.alert-error {
      color: $color-danger;
    }

    &.alert-notice {
      color: $color-notice;
    }

    // &.alert-success {
    //   color: $color-success;
    // }
  }

  &.notification {
    box-shadow: $main-spacing/2 0 $main-spacing $main-spacing * -1
      rgba(0, 0, 0, 0.15);
  }
}

/*.alert {
  &.list {
    background: $color-background-box;
    border: 1px solid $color-danger;
    color: $color-danger;
  }
}*/
