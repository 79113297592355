@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.logo-container {
  text-align: center;
  display: flex;
  padding: $main-spacing 0;
  width: 100%;

  // @include gt-xs {
  //   padding: $main-spacing $main-spacing $main-spacing $main-spacing;
  // }

  &.hidden {
    @include lt-md {
      display: none;
    }
  }

  &.no-padding {
    padding: 0;
    width: auto;
  }

  &.to-right {
    justify-content: flex-end;
  }
}
