@import '../../../config/variables.scss';

.language-selector {
  display: flex;
  justify-content: flex-end;
  margin: 0 (-$main-spacing/4);
}

.language-flag-container {
  margin: $main-spacing/4;
}

.language-flag {
  width: 2rem;
  border: 1px solid #eee;
  padding: 0;
  cursor: pointer;
  img {
    display: block;
  }

  &:hover {
    border: 1px solid rgba($color-primary, 0.5);
  }

  &.active {
    border: 1px solid $color-primary;
    box-shadow: 0 0 $main-spacing/2 0 rgba($color-primary, 0.5);
  }
}
