@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.hello-text-wrapper {
  display: flex;
  flex-direction: column;
  padding: $main-spacing * 2.5 0;

  .mobile-hello-text {
    @include gt-sm {
      display: none;
    }
  }

  .header-hello-text {
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: $main-spacing/2;
  }

  .subtitle-hello-text {
    font-size: 1.3rem;
  }

  &.hidden {
    @include lt-md {
      display: none;
    }
  }

  &.mobile-style {
    position: absolute;
    bottom: 0;
    padding: $main-spacing;
    .header-hello-text {
      color: $color-background;
      font-size: 1.6rem;
      font-weight: 600;
    }
  
    .subtitle-hello-text {
      color: $color-background;
      font-size: 1.2rem;
    }
  }
}
