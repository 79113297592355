@import '../../config/variables.scss';
@import '../../config/mixins.scss';

.offer-section {
  width: 100%;
  background-color: $color-primary-light;
  display: flex;
  justify-content: center;
  font-size: 13px;

  @include gt-sm {
    font-size: 15px;
  }
}
