@import '../../../../config/variables.scss';

.single-change {
  display: flex;
  font-size: 0.9rem;
  align-items: center;
  justify-content: space-between;
  span:first-of-type {
    margin: 0 $main-spacing/6;
  }

  .quantity-separator {
    margin: 0 $main-spacing/6;
  }

  .single-change-content {
    display: flex;
    align-items: center;
  }
}
