@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.profile-details {
  padding: $main-spacing/2;
  .user-mail {
    color: $color-background;
    font-style: italic;
    font-size: 0.85rem;
  }

  @include gt-sm {
    padding: $main-spacing * 1.5 $main-spacing;
  }
}
