@import "variables";

%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

$gutter: $main-spacing;

@mixin grid($cols) {
  $base: 100% / $cols;
  @each $res in xs, sm, md, lg {
    @media screen and (min-width: map-get($breakpoints, $res)) {
      @for $i from 1 through $cols {
        .col-#{$res}-#{$i} {
          flex: 0 1 $base * $i;
        }
      }
    }
  }
}

.row {
  @extend %clearfix;
  margin-left: -$gutter;
  margin-right: -$gutter;
  display: flex;
  flex-wrap: wrap;

  [class*="col-"] {
    flex: 0 1 100%;
    padding-left: $gutter;
    padding-right: $gutter;
    min-height: 1px;
    display: flex;
    flex-direction: column;

    &.vertical {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  @include grid(12);
}
