@import "./variables.scss";

@mixin lt-xs {
  @media screen and(max-width: map-get($breakpoints, xs) - 1) {
    @content;
  }
}

@mixin lt-sm {
  @media screen and(max-width: map-get($breakpoints, sm) - 1) {
    @content;
  }
}

@mixin lt-md {
  @media screen and(max-width: map-get($breakpoints, md) - 1) {
    @content;
  }
}

@mixin gt-xs {
  @media screen and(min-width: map-get($breakpoints, xs)) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and(min-width: map-get($breakpoints, sm)) {
    @content;
  }
}

@mixin gt-md {
  @media screen and(min-width: map-get($breakpoints, md)) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and(min-width: map-get($breakpoints, lg)) {
    @content;
  }
}

@mixin container-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/*time-sheet-for-invoice-table-header */
.time-sheet-for-invoice-table-header {
  padding: $main-spacing/2;
}

/*tax-rate-table*/
@mixin invoice-table {
  margin-top: $main-spacing/2;
  margin-bottom: $main-spacing;
  min-width: 100%;
}

.tax-rate-table-header {
  padding: $main-spacing/2;
}
/*tax-rate-header-item*/
@mixin invoice-table-header {
  padding: $main-spacing * 0.25;
  font-weight: 600;
  font-size: 1rem;
  color: $color-primary;
  text-align: center;
}
@mixin invoice-table-row {
  font-size: 0.9rem;
  padding: $main-spacing * 0.25;
  border-top: 1px solid $color-primary;
  border-bottom: 1px solid $color-primary;
  text-align: center;
  vertical-align: middle;
  font-family: $main-font;
}

.tax-rate-header {
  margin-top: $main-spacing;
}

.tax-table-sum-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding-bottom: $main-spacing/2;
  font-weight: 600;
}

@mixin show($time, $delay: 0) {
  animation-name: show;
  animation-delay: $delay;
  animation-duration: $time;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-timing-function: ease in;
}