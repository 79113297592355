@import '../../../../config/variables.scss';
@import '../../../../config/mixins.scss';

.tab-wrapper {
  margin-bottom: $main-spacing;

  &.no-margin {
    margin-bottom: 0;
  }

  .tab-content {
    justify-content: space-between;
    border: 1px solid $color-primary;
    padding: $main-spacing/4 $main-spacing;
    align-items: center;
    display: flex;
    width: 100%;

    .tab-name {
      &.bold {
        font-size: 1.2rem;
        font-weight: 700;
      }
    }
  }
  .icon-background {
    background-color: $color-primary;
    color: $color-background;
    display: flex;
    align-items: center;
    padding: 0 $main-spacing/1.6;
  }
  .item-position {
    background-color: darken($color-primary, 20%);
    color: $color-background;
    display: flex;
    align-items: center;
    padding: 0 $main-spacing/1.6;
    font-weight: 700;
  }
  .field-type {
    display: flex;
    align-items: center;
  }
}
