@import '../../../../config/variables.scss';

.single-block {
  padding: $main-spacing/2;
  font-size: 0.8rem;
  background: darken($color-background, 20%);
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  margin: 0 $main-spacing/4;
  transition: $transition-time;
  margin-bottom: $main-spacing/2;
  flex: 0 0 auto;
  cursor: pointer;

  min-height: 50px;
  min-width: 50px;

  .text-group {
    display: flex;
    flex-direction: column;

    .input-container {
      min-width: 200px;
    }
  }

  &.dragging {
    cursor: move;
  }

  &.group {
    &:hover {
      background: darken($color-primary, 0%);
    }
  }

  &.active-dropzone {
    box-shadow: $soft-shadow-bottom;
    background: lighten($color-primary, 20%);
    border: 1px solid $color-primary;
  }

  .content {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;

    > .single-block {
      margin-bottom: 0;
    }
  }
}

.delete-block {
  cursor: pointer;

  border: none;
  background: none;
  font-size: 0.7rem;
  background: $color-danger;
  color: $color-text-light;
  padding: $main-spacing/4;
  margin-left: $main-spacing/4;
  margin-right: $main-spacing/8;
}

.group {
  background: darken($color-background, 30%);
  border: 1px dashed $color-text;
  min-width: 80px;
}
