@import '../../../../config/variables.scss';

.field-options-container {
  position: absolute;
  width: 100%;
  left: 0;
  background-color: rgba($color-text, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.field-options-select {
  width: 100%;
  max-width: 500px;
  background-color: $color-background;
  padding: $main-spacing;
  border-radius: $main-spacing/4;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: $soft-shadow-bottom;
}
