@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.app-wrapper {
  display: flex;
  padding-top: $header-height-mobile;
  height: 100%;
  min-height: 100vh;
  &.trial {
    padding-top: $header-height-mobile + $trial-height-mobile;
  }

  &.not-logged {
    padding-top: $header-height-mobile;
  }
  &.full {
    padding-top: 0;
  }

  @include gt-md {
    padding-top: $header-height-desktop;
    &.trial {
      padding-top: $header-height-desktop + $trial-height-desktop;
    }

    &.not-logged {
      padding-top: $header-height-desktop;
    }
  }
}
