@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.wrapper {
  display: flex;
  width: 100%;

  &.wrapper-column {
    flex-direction: column;
  }

  &.wrapper-primary {
    background-color: $color-primary;
    margin: 0 (-$main-spacing);
    color: $color-background;
    width: auto;
    padding: $main-spacing;
    p {
      color: $color-background;
    }

    @include gt-sm {
      padding: $main-spacing * 1.5;
      margin: 0 (-$main-spacing * 1.5);
    }
  }
}
