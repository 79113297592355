@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.menu-toggle {
  width: $width-line;
  height: $height-icon;
  position: relative;
  display: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  .line {
    display: block;
    background: $color-primary;
    width: $width-line;
    height: $height-line;
    position: absolute;
    left: 0;
    border-radius: ($height-line / 2);
    transition: all $transition-time;
    &.line-1 {
      top: 0;
    }
    &.line-2 {
      top: 50%;
    }
    &.line-3 {
      top: 100%;
    }
  }
  &:hover,
  &:focus {
    .line-1 {
      transform: translateY($height-line / 2 * -1);
    }
    .line-3 {
      transform: translateY($height-line / 2);
    }
  }
  &.toggled {
    .line {
      background: $color-text-light;
    }

    .line-1 {
      transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
    }
    .line-2 {
      opacity: 0;
    }
    .line-3 {
      transform: translateY($translateY * -1) translateX($translateX)
        rotate($rotation * -1);
    }
  }

  @include lt-md {
    display: block;
    z-index: 1;
  }
}
