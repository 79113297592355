@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.field-box {
  border: 1px solid $color-primary;
  background-color: $color-primary-light;
  margin-right: $main-spacing/2;
  margin-bottom: $main-spacing/2;

  .field-text {
    color: $color-primary;
    text-transform: uppercase;
    padding: $main-spacing/4 $main-spacing/2;
  }
}
