@import "../../../config/variables.scss";

.app-status {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
  background-color: $color-background;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: $main-spacing;

  color: $color-text;
  .app-status-title {
    font-size: 1.5rem;
    margin-bottom: $main-spacing;
    color: $color-text;
  }
}

.nowifi-icon {
  fill: $color-text;
  width: 80px;
  height: 80px;
  margin: $main-spacing * 2 0;
}

.offline-logo-container {
  width: 80vw;
  max-width: 300px;
}
