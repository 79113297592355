@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.offer-info {
  margin-bottom: $main-spacing * 2;
  @include gt-sm {
    display: flex;

    .client-data {
      flex: 0 0 50%;
      margin-right: $main-spacing/4;
    }
    .contact-person-data {
      flex: 0 0 50%;
      margin-left: $main-spacing/4;
    }
  }
}

.company-name {
  font-size: 1.5em;
  font-weight: 700;
  padding-bottom: $main-spacing/2;
}

.company-address {
  font-size: 1.2em;
  font-weight: 400;
  padding-bottom: $main-spacing;
}

.company-details {
  font-size: 1.2em;
  font-weight: 400;

  &.with-padding {
    padding-bottom: $main-spacing / 2;
  }

  .bold {
    font-weight: 600;
  }
}
.offer-receiver {
  font-size: 1.4rem;
  margin-bottom: $main-spacing/2;
}
