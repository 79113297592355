@import "../../../../config/variables.scss";

.modal-header {
  display: flex;
  justify-content: space-between;
  padding-right: $main-spacing;
  margin-bottom: $main-spacing/2;
}

.modal-exit {
  color: $color-primary;
  font-size: 2.5rem;
  transition: $transition-time color;
  cursor: pointer;
  line-height: 2.5rem;
  position: absolute;
  top: $main-spacing / 4;
  right: $main-spacing / 4;

  &.navigation {
    top: 10px;
    right: 10px;
  }
  &:hover {
    color: lighten($color-primary, 20%);
  }
}
