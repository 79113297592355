@import '../../../../config/mixins.scss';
@import '../../../../config/variables.scss';

.nav-tab-wrapper {
  width: 100%;
  display: block;
  margin: $main-spacing 0 2 * $main-spacing;

  @include gt-sm {
    display: flex;
  }

  .nav-tab-button {
    font-size: 1.4rem;
    padding: $main-spacing;
    display: flex;
    flex: 0 1 50%;
    justify-content: center;

    &:hover {
      background-color: $color-primary-light;
      cursor: pointer;
    }

    &.active {
      background-color: $color-primary-light;
      color: $color-primary;
      font-weight: 700;
      border-bottom: 3px solid $color-primary;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
