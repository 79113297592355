@import '../../../config/variables.scss';

.option-remove {
  flex: 0 1 auto;
  height: 50px;
  padding: $main-spacing;
  min-width: unset;
  margin-top: calc(0.9rem - #{$main-spacing/4});
  margin-left: $main-spacing/2;
}

.single-option {
  background: darken($color-background, 5%);
  padding: $main-spacing/2;
  margin-bottom: $main-spacing;
}

.single-option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
