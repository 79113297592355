@import "../../../../config/variables.scss";
@import "../../../../config/mixins.scss";

.menu-main {
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  justify-items: flex-start;

  .main-menu-content {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    flex-grow: 2;
  }

  .menu-desktop {
    display: none;

    @include gt-md {
      display: block;
    }
  }
}
