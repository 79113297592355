@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

main {
  min-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.stretch {
    display: flex;
    justify-content: space-between;
  }
}
