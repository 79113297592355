@import "./config/variables.scss";
@import "./config/reset.scss";
@import "./config/typography.scss";
@import "./config/forms.scss";
@import "./config/grid.scss";

* {
  margin: 0;
}
html,
body {
  height: 100%;
}

body {
  background-color: $color-background;
}
