@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;

  @include gt-md {
    position: relative;
  }
  .bottom-wrapper {
    max-width: $form-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .button {
    margin-bottom: $main-spacing * 0.5;
  }
}
