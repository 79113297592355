@import '../../../config/variables.scss';

.divider {
  width: 100%;
  margin: $main-spacing 0;
  border-bottom: 2px solid $color-primary;

  &.light {
    border-bottom: 2px solid $color-background;
  }
}